import {DashBoardTable}  from 'onaosreact'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { addToSelected, removeToSelected, selectAll, unselectAll } from '../../../redux/journal/journalDatas'


export const ReportTable = (props) => {

    const userDatas = useSelector( (state) => state.journalDatas.datasReport)
    const datasSelect = useSelector( state => state.journalDatas.codesSelected)
    const dispatch = useDispatch()

    const [datasBody, setDatasBody] = useState(userDatas.body)
    const [filters, setFilters] = useState([])
    const [listFilters, setListFilters] = useState([])


    useEffect( ()=> {
        const tmp = [...listFilters]
        if (userDatas.body !== undefined) {
            userDatas.head.map((v, k) => {
               tmp[k] =  [...new Set(userDatas.body.map((line) => line[k]  ))].sort()
            })
            setListFilters(tmp)
        }
    }, [])


    const toggleRow = (id) => {
        if (datasSelect.body.filter(line => line === userDatas.body[id]).length >0) {
            dispatch(removeToSelected(id))
        } else {
            dispatch(addToSelected(id))
        }
    }

    const toggleAll = (state) => {
        if (state) {
            dispatch(selectAll(datasBody))
        } else {
            dispatch(unselectAll(datasBody))
        }
    }

 
    const filterLine = (pos, value) => {

        const fil = [...filters]

        if (fil[pos] === undefined) {
            fil[pos] = Array(0)
            fil[pos][0] = value
        } else {
            if (fil[pos].filter(v => v === value ).length !==  0) {
                fil[pos] = fil[pos].filter(v => v !== value )

            } else {
                fil[pos].push(value)
            }
        }
        


        const tmp = userDatas.body.filter(line =>             
            line.filter((t, p) => fil[p] !== undefined  && fil[p].includes(t)).length > 0
        );


        setDatasBody(tmp)
        setFilters(fil)
    }



    return (
        <div style={{ height: '100%', overflow: 'hidden auto' }}>
            <DashBoardTable
                head={userDatas.head}
                body={datasBody}
                checkbox={true}
                onChange = {(array,id) => toggleRow(id)}
                stateChangeAll = {toggleAll}
                onFilterChange = {filterLine}
                filterList = {listFilters}
                filterCheck  = {filters}
                />
        </div>

    )
}
