import React from "react"
import {Title, MenuItem, NeuMorphisme} from "onaosreact"
import 'onaosreact/dist/index.css';
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../redux/dashboardmenu/DashBoardMenu";
import { getAPI } from "../../api/api";
import { fetchCodes, fetchReport } from "../../redux/journal/journalDatas";
import { fetchDictionaries } from "../../redux/dictionnary/dictionnary";
import { fetchScenarios } from "../../redux/UserDatas/UserDatas";
import { useSnackbar } from "notistack";
import { fetchSGL } from "../../redux/sgl/sgl";


export const Menu = (props) => {

    const dispatch = useDispatch()
    const DashBoardMenu = useSelector( (state) =>state.DashBoardMenu ) 
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const page = {
        "Mon SGL" : "sgl",
        "Mon dictionnaire" : "import",
        "Mes scénarios" : "scenar",
        "Traçabilité" : "journal",
        "Rapport de conformité" : "report",
    }


    const loadDatasJournal = (page) => {
        getAPI("/allcodesalldictionnary", tokenRefresh).then(data =>{
            dispatch(fetchCodes({codes : data}) )
            dispatch(setPage(page))
        })
    }

    const loadDatasReport = (page) => {
        enqueueSnackbar("Recherche de vos codes en cours", {variant: 'info'})
        getAPI("/loadallcodes", tokenRefresh).then(data =>{
            closeSnackbar()
            dispatch(fetchReport({datas : data}) )
            dispatch(setPage(page))
        })
    }


    const loadDicto = (page) => {
        getAPI("/dictionnaries",tokenRefresh).then( data => {
            dispatch(fetchDictionaries({list : data}))
            dispatch(setPage(page))
        })
    }

    const loadScenar = (page) => {
        getAPI("/allscenarios",tokenRefresh).then( data => {
            if (data.head !== undefined)  dispatch(fetchScenarios({scenarios : data}))
            dispatch(setPage(page))
        })
    }

    const loadSGL = (page) => {
        getAPI("/sgls", tokenRefresh).then(data => {
            dispatch(fetchSGL({list : data}))
            dispatch(setPage(page))
          })
    }


    const setPageMenu = (page) => {

        switch(Object.entries(page)[1][1]){
            case 'journal':
                loadDatasJournal(page)
                break
            case 'import':
                loadDicto(page)
                break
            case 'report':
                loadDatasReport(page)
                break
            case 'scenar':
                loadScenar(page)
                break
            case 'sgl':
                loadSGL(page)
                break
            default:
                dispatch(setPage(page))
                break
        }

    

    }


    const styleDiv = {
        width:"250px",
        height:"90vh",
        position:'absolute',
        top:'70px',
        left:'50px',
    }

    return(
        <NeuMorphisme  style = {styleDiv}>
   
            <Title text = "Navigation" />
            {Object.entries(page).map( v => (
                <MenuItem 
                    text = {v[0]}
                    click = {()=> setPageMenu(v)}
                    active = {DashBoardMenu.page[1] === v[1]}
                    key = {v[1]}
                    style = {{fontSize : '14px'}}
                />
            ))}
            
            </NeuMorphisme>
    )
}