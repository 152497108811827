import { createSlice } from "@reduxjs/toolkit";



export const requestCodesSlice = createSlice({
    name : "requestCodes",
    initialState:{
        all : [], 
        listCodesInfos : [],
        hoverCodes : {result : [], request : []}
    },
    reducers: {
        fetchAllCode  : (state, action) => { //open window for new name
            state.all = action.payload.allcodes
            return state
        },

        addListCodesInfos : (state, action) =>{
            state.listCodesInfos.push(action.payload.infos)
            return state
        },

        loadListCodes : (state, action) => {
            state.listCodesInfos = action.payload.codes
            return state
        },

        setActiveLine : (state, action) => {
            const requestLine = action.payload.requestLine
            const resultLine = action.payload.resultLine
            const newStateActive = action.payload.type === "demande" ? !state.listCodesInfos[requestLine]["demande"]["active"] : !state.listCodesInfos[requestLine]["resultats"][resultLine]["active"]

            state.listCodesInfos.forEach((v, k) =>   {
                v.demande.active = false
                v["resultats"].forEach((vv, kk) => {
                    state.listCodesInfos[k]["resultats"][kk]['active'] = false
                })
            })
            if (action.payload.type === "demande") {
                state.listCodesInfos[requestLine]["demande"]["active"] = newStateActive
            } else {
                state.listCodesInfos[requestLine]["resultats"][resultLine]["active"] = newStateActive
            }




            return state
        },

        removeActiveLine : (state, action) => {
            state.listCodesInfos.forEach((v, k) =>   {
                v.demande.active = false
                v["resultats"].forEach((vv, kk) => {
                    state.listCodesInfos[k]["resultats"][kk]['active'] = false
                })
            })
            return state
        },

        setHoverLine : (state, action) => {
            const tmp = {result : [], request : []}
            if (action.payload.line === -1){
                state.hoverCodes = tmp
            } else {
                const type = action.payload.type
                tmp[type][action.payload.block] = []
                tmp[type][action.payload.block][action.payload.line] = true
                state.hoverCodes =  tmp
            }

            return state
        },

        removeRequestCode : (state, action) => {
            state.listCodesInfos.splice(action.payload.position,1)
            return state
        },

        setConformOrNot : (state, action)=> {
            state.listCodesInfos.forEach((v, k) =>   {

                if (v.demande.code === action.payload.requestCode) {
                    state.listCodesInfos[k]['demande']['stateall'] =  action.payload.requestCodeState
                    if (action.payload.typeCode === "demande") {
                        v.demande.state = action.payload.action
                    } else {
                        v.resultats.forEach((vv, kk) => {
                            if (vv.code === action.payload.resultCode) {
                                state.listCodesInfos[k]["resultats"][kk]["state"] = action.payload.action
                            }
                        })
                    }

                } 
            })

            return state
        },
        setNewLOINC : (state, action) => {
            state.listCodesInfos.forEach((v, k) => {
                if (v.demande.code === action.payload.infos.requestCode) {
                    if (action.payload.infos.codeActiveType === "demande") {
                        const txt = state.listCodesInfos[k]["demande"].loinc 
                        state.listCodesInfos[k]["demande"].loinc = action.payload.infosloinc.newCode + " ("+ action.payload.infosloinc.oldCode +")"  
                        return state
                    } else {
                        v.resultats.forEach((vv, kk) => {
                            if (vv.code === action.payload.infos.active) {
                                const txt = state.listCodesInfos[k]["resultats"][kk].loinc
                                state.listCodesInfos[k]["resultats"][kk].loinc = action.payload.infosloinc.newCode + " ("+ action.payload.infosloinc.oldCode +")"  
                                state.listCodesInfos[k]["resultats"][kk].oldloinc = state.listCodesInfos[k]["resultats"][kk].libelleloinc
                                state.listCodesInfos[k]["resultats"][kk].libelleloinc = action.payload.infosloinc.newlibelle
                                return state
                            }
                        })
                    }
                }
            })


            return state
        },
        removeNewLOINC : (state, action) => {
            state.listCodesInfos.forEach((v, k) => {
                if (v.demande.code === action.payload.infos.requestCode) {
                    if (action.payload.infos.codeActiveType === "demande") {
                        state.listCodesInfos[k]["demande"].loinc = action.payload.loincInfos.code
                        return state
                    } else {
                        v.resultats.forEach((vv, kk) => {
                            if (vv.code === action.payload.infos.active) {
                                const txt = state.listCodesInfos[k]["resultats"][kk].loinc
                                state.listCodesInfos[k]["resultats"][kk].loinc = action.payload.loincInfos.code
                                state.listCodesInfos[k]["resultats"][kk].libelleloinc =  action.payload.loincInfos.libelleloinc
                                state.listCodesInfos[k]["resultats"][kk].oldloinc = undefined
                                return state
                            }
                        })
                    }
                }
            })


            return state
        }
 
    }
})


export const {
    fetchAllCode, addListCodesInfos, loadListCodes, setActiveLine, removeActiveLine, 
    removeRequestCode, setHoverLine, setConformOrNot, setNewLOINC  , removeNewLOINC
} = requestCodesSlice.actions;