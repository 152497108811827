import { useSnackbar } from 'notistack'
import { ButtonBluePlus, InputSearchDash } from 'onaosreact'
import React  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPI } from '../../../api/api'
import { fetchForScenario } from '../../../redux/dictionnary/dictionnary'
import { openScenario } from '../../../redux/scenario/scenario'
import { fetchForScenarioSGL} from '../../../redux/sgl/sgl'




export const HeadNav = (props) => {

    const dicos = useSelector(state => state.dictionnary.list.infos)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    
    const searchBar = {
        display : "flex",
        width : "100%",
        margin : "10px auto 20px 20px",
        alignItems : "center"
      }
    
      const newScenar = () => {
        // TODO : list dico and sgl , error if one or two not find
        getAPI("/getSGLAndDict", tokenRefresh).then(data => {
            if (data.dicos.length === 0) {
                enqueueSnackbar("Aucun dictionnaire de Résultats trouvé !", {variant: 'error'})
                return
            }
            if (data.sgls.length === 0) {
                enqueueSnackbar("Aucun SGL trouvé !", {variant: 'error'})
                return
            }
            dispatch(fetchForScenario(data.dicos))
            dispatch(fetchForScenarioSGL(data.sgls))
            dispatch(openScenario())

        })        
      }
    


    return(
        <div style = {searchBar}>
            <ButtonBluePlus 
                text = "Nouveau scénario"
                onClick = {newScenar}
            />
            {/* <InputSearchDash
                placeHolder = "Rechercher des éléments dans vos scénarios"
                style = {{marginLeft : "100px"}}
            /> */}
        </div>
    )



}