import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { putAPI } from '../api/api.js';

import { MenuViewer } from '../menu/MenuViewer.js';
import { doEdit, setNavMenu } from '../redux/scenario/scenario.js';
import { showSearch } from '../redux/search/search.js';
import { CDA } from './CDA/CDA.js';
import { Search } from './search/Search.js';



export const Scenarios = (props) => {
    const openSearch = useSelector(state => state.search.open)
    const scenarioID = useSelector(state => state.scenario.scenarioID)
    const datasDisplay = useSelector(state => state.scenario.codeInfos)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const dispatch = useDispatch()


    const onClose = () => {
        dispatch(showSearch({open : false}))
    }



    const changeLOINC = (newCode) => {
        const ID = datasDisplay.datas.metainfo.id 

        const dataEdit = {
            item : "Code LOINC",
            codeIDCDA : ID,
            scenarioID : scenarioID,
            codeID : datasDisplay.datas.metainfo.idlocal,
            action : "edit",
            value: newCode
        }
        putAPI("/editCode", tokenRefresh, dataEdit).then(data => {
            if (data.ok) {
              dispatch(doEdit({
                item : "Code LOINC",
                value : newCode
              }))
            }
            onClose()
        })
    }

  


    return (
        <React.Fragment>
            {openSearch && <Search 
                onValidate = {changeLOINC} 
                onClose = {onClose}
            />}

            <MenuViewer />
            <CDA />
        </React.Fragment>
        
     )

}