import { createSlice } from "@reduxjs/toolkit";



export const sglSlice = createSlice({
    name: "sgl",
    initialState: {
        list : {
            head :[
                    'SGL par défaut','Version',  'Logiciel','Editeur', "Ajouté par", "Ajouté le"
                ],
            body : [

                ],
            infos:[],
            datas : {
                selected : -1
            },
        },
        listingToAdd : [],
        open:false,
        listingForScenario : []

    },


    reducers: {
        fetchSGL: (state, action) => {
            if (action.payload.list.head !== undefined) {
                state.list.head = action.payload.list.head
                state.list.body = action.payload.list.body
                state.list.infos = action.payload.list.notshow
                state.list.datas = action.payload.list.datas
            } 
            return state
        },
        openSGL: (state, action) => {
            state.open = true
            return state
        },
        closeSGL: (state, action) => {
            state.open = false
            return state
        },
        fetchAllSGLDefault : (state, action) => {
            state.listingToAdd = action.payload
            return state
        },
        defineSGLCheck : (state, action) => {
            state.list.datas.selected = action.payload
            return state
        },
        fetchForScenarioSGL : (state, action)=> {
            state.listingForScenario = action.payload
            return state
        }


    }
})


export const {fetchSGL, openSGL, closeSGL, fetchAllSGLDefault, defineSGLCheck, fetchForScenarioSGL} = sglSlice.actions;