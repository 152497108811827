import { createSlice } from "@reduxjs/toolkit";


const init = {
    
        head : [
                'Code','Libellé', 'SGL', 'Dictionnaire', 'Scénario', 'Expert', 'Statut du code', 'Chargé le'
            ],
            body :[

            ]
    
}

export const journalDatasSlice = createSlice({
    name: "journalDatas",
    initialState: {
        datas : init,
        datasReport :init,
        codesSearch : [],
        codesSelected : {donotshow : [], body : []}
    },
    reducers: {
        fetchJournal: (state, action) => {
            state.datas = action.payload.datas
            return state
        },
        fetchReport: (state, action) => {
            state.datasReport = action.payload.datas
            state.codesSelected = {donotshow : [], body : []}
            return state
        },

        fetchCodes : (state, action) => {
            state.codesSearch = action.payload.codes
            state.datas = init
            return state
        },
        addToSelected : (state, action) => {
            const index = action.payload
        
            state.codesSelected.body.push(state.datasReport.body[index])
            state.codesSelected.donotshow.push(state.datasReport.donotshow[index])

            return state
        }, 
        removeToSelected : (state, action) => {
            const index = action.payload
            state.codesSelected.body.splice(state.datasReport.body[index],1)
            state.codesSelected.donotshow.splice(state.datasReport.donotshow[index],1)

            return state
        }, 
        selectAll : (state, action) => {
            state.codesSelected.body = state.datasReport.body
            state.codesSelected.donotshow = state.datasReport.donotshow
            return state
        },
        unselectAll : (state, action) => {
            state.codesSelected =  {donotshow : [], body : []}
            return state
        },
    }
})

export const { fetchJournal, fetchCodes, addToSelected, removeToSelected, fetchReport,selectAll,unselectAll } = journalDatasSlice.actions;