import { createSlice } from "@reduxjs/toolkit";



export const cdaDatasSlice = createSlice({
    name : "cdaDatas",
    initialState:{
        open : false, 
        datas : []
    },
    reducers: {
        openCDA  : (state, action) => {
            state.open = action.payload.open
            return state
        },
        loadCDADatas : (state, action) => {
            state.datas = action.payload.datas
            state.open = true
            return state
        }

   
    }
})


export const { openCDA, loadCDADatas } = cdaDatasSlice.actions;