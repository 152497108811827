import { DictionnaryTable } from './DictionnaryTable'
import { HeadNavImport } from './HeadNavImport'
import { ImportFile } from './ImportFile'

export const DashImport = (props) => {

     return(
        <div style = {{height : '100%', overflow : 'hidden'}}>
            <ImportFile />
            <HeadNavImport />
            <DictionnaryTable />
     </div>
    )


}