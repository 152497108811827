import { ModalImportResultats, ModalWindow } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeDictionaries, fetchDictionaries } from '../../../redux/dictionnary/dictionnary'
import { useSnackbar } from 'notistack';
import { getAPI, postAPI } from '../../../api/api';



export const ImportFile = (props) => {

    const dictOpen = useSelector(state => state.dictionnary)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const institution = useSelector(state => state.log.institution)
    const email = useSelector(state => state.log.email)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const dispatch = useDispatch()

    const closeDict = () => {
        dispatch(closeDictionaries())
    }

    const postDict = (datas) => {
        //send file to API
        //error
        if (datas.nameFile === "" ||  datas.fileBrowseName === "") {
            enqueueSnackbar("Merci de sélectionner un fichier csv et de lui attribuer un nom", {variant: 'error'})
        } else {
            const formData = new FormData()
            formData.append('File', datas.filePath)
            formData.append('fileName',datas.nameFile)
            formData.append('email',email)
            enqueueSnackbar("Envoi en cours", {variant: 'info', persist : true})

            postAPI("/sendDict", tokenRefresh, formData).then(data =>{
                if (data.ok){
                    if (data.errorname) {
                        enqueueSnackbar("Un dictionnaire avec ce nom existe déjà, merci d'en choisir un autre", {variant: 'error'})
                    } else {
                        enqueueSnackbar('Fichier importé avec succès', {variant: 'success'})
                        getAPI("/dictionnaries",tokenRefresh).then( data => {
                            dispatch(fetchDictionaries({list : data}))
                            dispatch(closeDictionaries())
                        })
                    }
                } else {
                    enqueueSnackbar("Une erreur s'est produite : "+data, {variant: 'error'})
                    dispatch(closeDictionaries())
                }
            })
        }
    }


    return(
        <React.Fragment>
        {dictOpen.open &&
        <ModalWindow>
            <ModalImportResultats
                title="Ajouter un dictionnaire de Résultats"
                save = {postDict}
                cancel = {closeDict}
            />
        </ModalWindow>
        }
        </React.Fragment>
    )




}