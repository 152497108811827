import { ModalAddSGL, ModalWindow } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack';
import {   putAPI } from '../../../api/api';
import { closeSGL, fetchSGL } from '../../../redux/sgl/sgl';



export const AddSGL = (props) => {

    const sgltoAdd = useSelector(state => state.sgl.listingToAdd)
    const email = useSelector(state => state.log.email)
    const loadSGL = useSelector( state => state.sgl.list)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const dispatch = useDispatch()

    const closeSGLWindow = () => {
        dispatch(closeSGL())
    }

    const addSGL = (datas, defaultDatas) => {
        datas.editor = datas.editor === "" && defaultDatas.editor !== "" ? defaultDatas.editor : datas.editor
        datas.software = datas.software === "" && defaultDatas.software !== "" ? defaultDatas.software : datas.software

        if (datas.editor === ""  ||  datas.software === "" || datas.version === "") {
            enqueueSnackbar("Merci de remplir les différents champs", {variant: 'error'})
        } else {
            datas.expert = email
            putAPI("/addSGL", tokenRefresh, datas).then(data =>{
                if (data.ok){
                    dispatch(fetchSGL({list : data}))
                    dispatch(closeSGL())
                }
            })
        }
    }


    const returnDefault = () => {
        const datas = {editor : undefined, software : undefined}
        if (loadSGL.datas !== undefined && loadSGL.datas.selected !== undefined && loadSGL.datas.selected !== -1 ) {
            if (loadSGL.body !== undefined && loadSGL.body[loadSGL.datas.selected] !== undefined){
                datas.editor = loadSGL.body[loadSGL.datas.selected][3]
                datas.software = loadSGL.body[loadSGL.datas.selected][2]
            }
        }

        return datas
    }



    return(
        <React.Fragment>
            <ModalWindow>
                <ModalAddSGL
                    title="Ajouter un SGL"
                    save = {addSGL}
                    cancel = {closeSGLWindow}
                    datas = {sgltoAdd}
                    defaultSelect = {returnDefault()}
                />
            </ModalWindow>
        </React.Fragment>
    )




}