import { createSlice } from "@reduxjs/toolkit";


export const focusInputSlice = createSlice({
    name : "focusInput",
    initialState:{
        showBackground : false, 
        refInput : null,
    },
    reducers: {
        setBackgroundFocus : (state, action) => {
            state.showBackground = action.payload.state
            return state
        },
        saveRef : (state , action ) => {
            state.refInput = action.payload.reference
            return state
        },
    }
})


export const {
    setBackgroundFocus, saveRef
} = focusInputSlice.actions;