


export const CDAFile =  ({iframeRef, url}) =>  {



    
    const style = {
        height : "98%", 
        border:"none",
        borderRadius : "5px", 
        width : "100%",
    }


    return (
        <iframe 
            ref={iframeRef}  
            src = { url } 
            style = {style} 
            title = "Fichier CDA"
        />
    )
};