import { InputSearch } from 'onaosreact'
import React, { useRef} from 'react'
import { getAPI, putAPI } from '../../../api/api'
import { useDispatch, useSelector } from 'react-redux'
import { fetchJournal } from '../../../redux/journal/journalDatas'


export const SearchBar = (props) => {

    const codesSearch = useSelector(state => state.journalDatas.codesSearch)

    const searchBar = {
        display : "flex",
        width : "100%",
        margin : "10px 0 20px 0",
        alignItems : "center",
        justifyContent : 'center',
    }

    const inputRef = useRef()
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const dispatch = useDispatch()

    const getCode = (code) => {
        const datas = {
            code : code
        }
        putAPI("/journalinfos",tokenRefresh, datas).then( data => {
            dispatch(fetchJournal({datas : data}))
        })
    }

    return (
        <div style={searchBar} >
            <InputSearch
                placeHolder="Rechercher un code dans vos dictionnaires"
                style={{width: "400px" }}
                onChoice= {getCode}
                auto={true}
                autoOptions={codesSearch}
                inputRef={inputRef}
            />
        </div>
    )
}