import { createSlice } from "@reduxjs/toolkit";



export const searchSlice = createSlice({

    name : "search",
    initialState:{
        open : false,
        loincResult : {
            head : [],
            body : []
        },
        filters : {},
        radioOptions : [
            {label : "Automatique", value : "auto"},
            {label : "Début par", value : "start"},
            {label : "Recherche exacte", value : "exact"},
            {label : "Contient tout", value : "all"},
        ],
        radioDefault : 'auto',
        showSaveFilter : false,
        listingCompletion : [],
        listingFilter : [  {label : "Filtres enregistrés", value : 0},],
        listingFilterNewName : "",
        temporaryHistoryCompletion : [],
        clientLoincInfos : {},
        clientLocalInfos : {}
    },
    reducers: {
        showSearch : (state, action) => {
            state.open = action.payload.open
            return state
        },
        setClientLOINCInfos : (state, action) => {
            state.clientLoincInfos = action.payload
            return state
        },
        setClientLocalInfos : (state, action) => {
            state.clientLocalInfos = action.payload
            return state
        },
        setResultsSearch : (state, action) => {
            state.loincResult = action.payload.datas
            return state
            //TODO : set autocompletion datas
        },
        setFiltersSearch : (state, action) => {
            state.filters = action.payload.filters
            return state
        },
        setRadiosOptions : (state, action) => {
            state.radioOptions = action.payload.radio
            return state
        },
        setRadiosChoice : (state, action) => {
            state.radioDefault = action.payload.radio
            return state
        },
        setFilterSearch : (state, action) => {
            state.listingFilter = action.payload
            return state
        },
        showFilterName : (state, action) => {
            state.showSaveFilter = action.payload
            return state
        },
        setAutoCompletionListing : (state, action) => {
            state.listingCompletion = action.payload
            return state
        },
        addHistoryCompletion : (state, action) => {
            if (state.temporaryHistoryCompletion.filter(val => val ===action.payload).length === 0) {
                state.temporaryHistoryCompletion.push(action.payload)
            }
            return state
        },
        setNewFilterName : (state, action) => {
            state.listingFilterNewName = action.payload
            return state
        }

    }



})

export const {showSearch, setResultsSearch, 
    setFiltersSearch, setRadiosOptions, setRadiosChoice, 
    setFilterSearch, showFilterName, setAutoCompletionListing, 
    addHistoryCompletion, setNewFilterName, 
    setClientLOINCInfos, setClientLocalInfos
} = searchSlice.actions;

