import { configureStore } from "@reduxjs/toolkit";
import { logSlice } from "../connection/log";
import { onaosSlice } from "../onaosprojects/onaosprojects";
import { fileSlice } from "../files/files";
import {DashBoardMenuSlice} from "../dashboardmenu/DashBoardMenu";
import { scenarioSlice } from "../scenario/scenario";
import { userdatasSlice } from "../UserDatas/UserDatas";
import { requestCodesSlice } from "../requestcode/requestcode";
import { codesClientInfosSlice } from "../codesclientinfos/codesclientinfos";
import { cdaDatasSlice } from "../cdadatas/cdadatas";
import { focusInputSlice } from "../focusinput/focusInput";
import { dictionnarySlice } from "../dictionnary/dictionnary";
import { journalDatasSlice } from "../journal/journalDatas";
import { overviewScenarioSlice } from "../overview/overviewScenario";
import { DictionnaryCodesSlice } from "../dictionnarycodes/dictionnaryCodes";
import { searchSlice } from "../search/search";
import { sglSlice } from "../sgl/sgl";


export const store = configureStore({
    reducer:{
        log : logSlice.reducer,
        onaosProject: onaosSlice.reducer,
        file: fileSlice.reducer,
        DashBoardMenu : DashBoardMenuSlice.reducer,
        scenario : scenarioSlice.reducer,
        userDatas :userdatasSlice.reducer,
        requestCodes : requestCodesSlice.reducer,
        codesClientInfos : codesClientInfosSlice.reducer,
        cdaDatas : cdaDatasSlice.reducer,
        focusInput : focusInputSlice.reducer,
        dictionnary : dictionnarySlice.reducer,
        journalDatas : journalDatasSlice.reducer,
        overviewScenario : overviewScenarioSlice.reducer,
        dictionnaryCodes : DictionnaryCodesSlice.reducer,
        search : searchSlice.reducer,
        sgl : sglSlice.reducer,
    },
});