
import { ModalWindow,ModalImportCDA } from 'onaosreact'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAPI, postAPI, putAPI } from '../../../api/api'
import { closeScenario, setCodesState, setNavTab, setScenarioID, setXML, setIDsList, eraseCodeInfos } from '../../../redux/scenario/scenario'
import { useSnackbar } from 'notistack';


export const NewScenario = (props) => {

    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const email = useSelector(state => state.log.email)
    const dicos = useSelector(state => state.dictionnary.listingForScenario)
    const sgls = useSelector(state => state.sgl.listingForScenario)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()



    const dispatch = useDispatch()
    const navigate = useNavigate()

    const cancelScenario = () => {
        dispatch(closeScenario())
    }

    const saveScenar = (name, fileDatas, dicoData, sglData ) => {
        if (name === "" || fileDatas === "" || dicoData === "" || sglData === "" ) {
            enqueueSnackbar("Un scénario incomplet !", {variant: 'error'})
            return
        }
        const datas = {
            scenarioName : name,
            //TODO : need to be discuss : only one scenario or one for specific slg+dico ?
            // dicoUUID : dicoData,
            // sglUUID : sglData
        }
        //first check only name ? and then import file ? 
        putAPI("/checkscenarioname", tokenRefresh, datas).then(datas => {
            if (datas.ok) {
                if (datas.errorname) {
                    enqueueSnackbar("Un scénario avec ce nom existe déjà, merci d'en choisir un autre", {variant: 'error'})
                } else {
                    enqueueSnackbar('Votre fichier CDA est en cours de traitement, merci de patienter', { 
                        variant: 'info',
                        persist: true,
                    }) 

                    const formData = new FormData()
                    formData.append('File', fileDatas)
                    formData.append('scenarioName',name)
                    formData.append('email',email)
                    formData.append('dicoUUID',dicoData)
                    formData.append('sglUUID',sglData)


                    setTimeout(()=>postAPI("/importCDA", tokenRefresh, formData).then(datas => {
                        if (datas.ok) {
                            getAPI("/generatedLink/"+datas.id, tokenRefresh).then(data => {
                                if (data.link){
                                    batch( ()=> {
                                        dispatch(setXML(process.env.REACT_APP_URL_FRONT + "/" + data.link))
                                        dispatch(setScenarioID(datas.id))
                                        if (data.states) dispatch(setCodesState(data.states))
                                        if (data.idList) dispatch(setIDsList(data.idList))
                                        dispatch(eraseCodeInfos())
                                        dispatch(setNavTab(0))
                                        cancelScenario()
                                        navigate("/scenarios"); 
                                    })
                                } 
                            })
                        } else {
                            //TODO : error gestion (name exist, other error)
                            enqueueSnackbar("Une erreur s'est produite : "+datas.error, {variant: 'error'})
                            cancelScenario()
                        }
                    }), 1000)
                }
            } else {
                enqueueSnackbar("Une erreur s'est produite : "+datas.error, {variant: 'error'})
                cancelScenario()
            }
        })


    }


    return(
        <ModalWindow>
            <ModalImportCDA
                title = "Nouveau scénario"
                placeHolder = "Nom du scénario"
                cancel = {cancelScenario}
                save = {saveScenar}
                datas = {{
                    dicos : dicos,
                    sgls: sgls
                }}
            />
        </ModalWindow>



    )




}