import { ButtonConform, DivLineCDA, NeuMorphisme, InternalTabulationMenu, TitleWithoutMargin, ButtonSelectAll } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { putAPI } from '../../../api/api'
import { onClickCDALine, onClickCDALineChange, setCodeInfos, setCodeState, setNavMenu, setNavMenusState, setNavMenuState, setNavTab, setSpecimenState, setSpecimenTab } from '../../../redux/scenario/scenario'
import { setClientLocalInfos, setClientLOINCInfos, showSearch } from '../../../redux/search/search'
import { CDAHead } from './CDAHead'




export const CDACheck = ({blockType}) => {

    const [dataTmp, setDatasTmp] = useState({})
    const [btnEnd, showEndBtn] = useState(false)
    const [btnNext, showNextBtn] = useState(false)

    const [isSpecific, setIsSpecific] = useState(false)


    const datasDisplay = useSelector(state => state.scenario.codeInfos)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const scenarioID = useSelector(state => state.scenario.scenarioID)
    const menusState = useSelector(state => state.scenario.navIteamState)
    const lineID = useSelector(state => state.scenario.codeInfos.datas.metainfo.id)

    const TabActif = useSelector(state => state.scenario.codeInfos.datas.specimenIndex)

    const menuStateItem = useSelector(state => state.scenario.navIteamState)

    const dispatch = useDispatch()


    const stateSelectAll = () => {
        if ((blockType === "CDA" || blockType === "CR") && datasDisplay.datas.metainfo.Specimen !== undefined && TabActif >=0 ) return datasDisplay.datas.metainfo.Specimen[TabActif].state === "conforme"
        if ( TabActif === 0 && datasDisplay.datas.metainfo.Specimen === undefined &&  menuStateItem !== undefined &&  menuStateItem[blockType] === "conforme") return true
        if ( datasDisplay.datas.metainfo.Specimen !== undefined && (blockType === "CDA" || blockType === "CR") &&  datasDisplay.datas.metainfo.Specimen[TabActif].state === "conforme" ) return true
        return false
    }


    const [isSelectAll, setSelectAll] = useState(stateSelectAll())


    useEffect(()=>{
        setIsSpecific((blockType === "CDA" || blockType === "CR") && datasDisplay && datasDisplay.datas && datasDisplay.datas.infos.Specific !== undefined)
        const stateAll = stateSelectAll()
        setSelectAll(stateAll)
    }, [TabActif])


    useEffect(()=>{
        if (Object.values(menusState).filter(v => v === "en cours" ||v === "à vérifier").length === 0) showEndBtn(true)
    }, [])
    


    const onClick = (type, block, item, mongokey) => {
        //api

        if (type !== "valid") {
            setSelectAll(false)
            showNextBtn(false)
        }
        const ID = datasDisplay.datas.metainfo.id 
   
        const specimenClic = TabActif-1
        const allDatas = isSpecific  && TabActif > 0 ? datasDisplay.datas.infos.Specific[specimenClic] :   datasDisplay.datas.infos.Commun

        //bloc (specimen X or Commun) state with new action 
        const tmp = Object.entries(allDatas).reduce((acc, current) => {
            const dd = Object.entries(current[1]).map(vv => {      
                return current[0] === block && vv[0] === item ? type :  vv[1].state
            })
            acc = acc.concat(dd)
            return acc
        }, [])
        const removeUndef = tmp.filter(line => line !== "notshow")

        const uniqTmp = [...new Set(removeUndef)]
        let blockState = ""

        if (uniqTmp.length === 1) {
            blockState = uniqTmp[0]
        } else {
            let blockStates = uniqTmp.filter(line => line === "tocheck")
            if (blockStates.length === 1) {
                blockState = "inprogress"
            } else {
                blockStates = uniqTmp.filter(line => line === "reject" || line === "force")
                if (blockStates.length > 0) {
                    blockState = "reject"
                }
            }
        }

        const datas = {
            scenarioID : scenarioID,
            codeID : ID,
            state : type,
            level:blockType,
            blockState : blockState,
            mongokey : mongokey,
            specificBloc : isSpecific ? TabActif : -1
        }
        
        putAPI("/entryChange",tokenRefresh, datas ).then(data => {
            if (data.ok){
                dispatch(onClickCDALine({block : block,item: item, state : type, specimen : TabActif}) ) //change color item
                const menuState = blockState === "inprogress" ? "en cours" : blockState === "reject" ? "non conforme" : blockState === "valid" ? "conforme" : "à vérifier"

                if (isSpecific && TabActif > -1) {
                    dispatch(setSpecimenState({index : TabActif, state : menuState }))
                    if (TabActif !== data.newTab && data.move !== "done") dispatch(setSpecimenTab(data.newTab)) 
                    const blockStateTmp =  data.stateBlock === "inprogress" ? "en cours" : blockState === "reject" ? "non conforme" : blockState === "valid" ? "conforme" : "à vérifier"

                    dispatch(setNavMenuState({item : blockType, value : blockStateTmp})) //change text state block
                    if (blockState === "valid") setSelectAll(true) ; else setSelectAll(false)

                } else {
                    dispatch(setNavMenuState({item : blockType, value : menuState})) //change text state block
                }

                if (data.IDState) dispatch(setCodeState({id : ID, state : data.IDState}))
                if (data.move && data.move !== "done" && btnEnd) showEndBtn(false)

                if (data.move && data.move !== blockType && data.move !== "done") {
                    const clickDatas = {
                        scenarioID : scenarioID,
                        line :  lineID,
                        action: data.move 
                    }
                    putAPI("/idinfos", tokenRefresh, clickDatas).then(datas => {
                        if (datas.datas && datas.datas.metainfo ){
                            batch(()=> {
                                dispatch(setCodeInfos({datas : datas.datas}))
                                dispatch(setNavMenu(datas.nav))
                                dispatch(setNavMenusState(datas.itemState))
                            })
                        }
                    })
                } else {
                    if (data.move === "done") showEndBtn(true)
                }
            }
        
        })

    }

    const checkGlobal = (v) => {
        const dd = Object.values(v).filter(line => line.state === "tocheck")
        if (dd.length === Object.values(v).length) return ""
        const dd2 = Object.values(v).filter(line => line.state === "valid")
        if (dd2.length === Object.values(v).length) return "valid"
        const dd3 = Object.values(v).filter(line => line.state === "reject")
        if (dd3.length === Object.values(v).length) return "reject"
        return ""
    }

    const setPageMenu = (valItem, n, loadLOINC = false) => {
        const clickDatas = {
            scenarioID : scenarioID,
            line : lineID,
            action: valItem,
        }

        putAPI("/idinfos", tokenRefresh, clickDatas).then(data => {
            if (data.datas && data.datas.metainfo ){
                batch(()=> {
                    dispatch(setNavMenusState(data.itemState))
                    if (!loadLOINC) dispatch(setCodeInfos({datas : data.datas}))
                    if (!loadLOINC) dispatch(setNavMenu(n))
                })
                if (loadLOINC) {
                    putAPI('/formatSearchClientData', tokenRefresh, data.datas.infos.Commun).then(data2 => {
                        batch(()=>{
                            dispatch(setClientLOINCInfos(data2.loinc))
                            dispatch(setClientLocalInfos(data2.local))
                            dispatch(showSearch({open : true}))
                            dispatch(setCodeInfos({datas : data.datas}))
                            dispatch(setNavMenu(n))
                        })
                    })
                }
            }
        })
    }

    const switchToEditLOINC = () => {
        setPageMenu("editlocal", 4, true)
    }




    const onAll = () => {

        const state = !isSelectAll ? "valid" : "tocheck"

        setSelectAll(!isSelectAll)



        const specimenClic = TabActif-1
        const allDatas = isSpecific  && TabActif > 0 ? datasDisplay.datas.infos.Specific[specimenClic] :   datasDisplay.datas.infos.Commun

        const datasAPI = {
            scenarioID : scenarioID,
            codeID : lineID,
            state : state,
            specificBloc : isSpecific ? TabActif : -1,
            infos : allDatas,
            level:blockType,
        }



        dispatch(onClickCDALineChange({ state : state, specimen : TabActif}) ) //change color item

        putAPI("/changeAll", tokenRefresh, datasAPI).then(data => {
            if (data.ok) {
                const clickDatas = {
                    scenarioID : scenarioID,
                    line :  lineID,
                    action: data.move === "done"  ? blockType : data.move 
                }
                putAPI("/idinfos", tokenRefresh, clickDatas).then(datas => {
                    if (datas.datas && datas.datas.metainfo ){
                        batch(()=>{
                            dispatch(setNavMenusState(datas.itemState))
                            // dispatch(setNavMenu(datas.nav))
                           // dispatch(setCodeInfos({datas : datas.datas}))
                           if (isSpecific && TabActif > -1) {
                                dispatch(setSpecimenState({index : TabActif, state : state === "valid" ? "conforme" : "à vérifier" }))
                           }

                            showEndBtn(data.move === "done")
                            if (state === "tocheck") {
                                dispatch(setNavMenu(datas.nav))
                                dispatch(setCodeInfos({datas : datas.datas}))
                                showNextBtn(false)
                            } else {
                                if (data.move === "done") {
                                    dispatch(setNavMenu(datas.nav))
                                    dispatch(setCodeInfos({datas : datas.datas}))
                                } else {
                                    showNextBtn(true)
                                    setDatasTmp({nav : datas.nav, datas : datas.datas })
                                }
                            }

              

                            if (data.IDState) dispatch(setCodeState({id : lineID, state : data.IDState})) //scenario state 
                        })
                    }
                })
                
            } 
        })


      //TODO : show "next" button and remove auto move => aim is to permit to check all and uncheck only some bad code
        // setTimeout(()=>{
        //     putAPI("/changeAll", tokenRefresh, datasAPI).then(data => {
        //         if (data.ok) {
        //             const clickDatas = {
        //                 scenarioID : scenarioID,
        //                 line :  lineID,
        //                 action: data.move === "done"  ? blockType : data.move 
        //             }
        //         putAPI("/idinfos", tokenRefresh, clickDatas).then(datas => {
        //                 if (datas.datas && datas.datas.metainfo ){
        //                     batch(()=>{
        //                         dispatch(setCodeInfos({datas : datas.datas}))
        //                         dispatch(setNavMenusState(datas.itemState))
        //                         setSelectAll(false)
        //                         dispatch(setNavMenu(datas.nav))
        //                         showEndBtn(data.move === "done")

        //                         if (data.IDState) dispatch(setCodeState({id : lineID, state : data.IDState})) //scenario state 
        //                     })
        //                 }
        //             })
                    
        //         } 
        //     })
        // }, 1000)    
    }

    const doNextBtn = () => {
        dispatch(setNavMenu(dataTmp.nav))
        dispatch(setCodeInfos({datas : dataTmp.datas}))
        showNextBtn(false)
    }
 

    return(
        <div style = {{display : "flex", flexDirection : 'column', height : "100%", gap : "16px"}}>
            {(blockType === "CDA" || blockType === "CR") && 
                <CDAHead datasDisplay={datasDisplay} />
            }

            <NeuMorphisme style = {{  minHeight : "50%" , display : "flex", flexDirection : "column", width : '95%', margin : '0px auto', }}  >
                <div style = {{display : "flex", flexDirection : "column",width : "98%",backgroundColor : "white", marginLeft : 'auto', marginRight : 'auto', borderRadius : '5px', padding : "5px", gap : "8px", overflow:'auto', height:"100%", position:"relative"}}>
                {datasDisplay.datas && datasDisplay.datas.infos && 
                    <ButtonSelectAll 
                        isOn = {isSelectAll}
                        handleToggle = {onAll}
                        style = {{ position : "absolute", top : "5px", right : "10px" }} 

                    />
                }


                {datasDisplay.datas && datasDisplay.datas.title && !Array.isArray(datasDisplay.datas.title) && 
                    <TitleWithoutMargin text = {datasDisplay.datas.title} />
                }
                {datasDisplay.datas && datasDisplay.datas.title && Array.isArray(datasDisplay.datas.title) && 
                    <div style = {{display : 'flex', width : "90%" }}>
                        {datasDisplay.datas.title.map (title => (
                            <div style = {{display : 'flex', width : "50%"}}>
                                    <TitleWithoutMargin text = {title} />
                            </div>
                        ))}
                    </div>                      
                }

                { (blockType === "LOINC" || blockType === "Local" ) && datasDisplay && datasDisplay.datas && datasDisplay.datas.infos && datasDisplay.datas.infos.Commun &&
                    <NeuMorphisme style = {{ display : "flex",  flexDirection : "column", width : '100%', margin : '5px auto', overflow:'auto'}}  >
                        {Object.entries(datasDisplay.datas.infos.Commun).map((v, k) =>(
                            <DivLineCDA 
                                key = {"divlivecda_"+k}
                                datas = {v[1]}
                                globalState = {checkGlobal(v[1])}
                                title = {Object.values(v[1]).length > 1 ? v[0] : ""}
                                onClick = {(state, p, mongokey) => onClick(state,v[0], p, mongokey)}
                                doubleTitle = {true}
                            />
                        ))}
                    </NeuMorphisme>   
                }
 
                { blockType === "Local" && 
                <div style = {{display : 'flex', justifyContent:'flex-end', alignItems:'flex-end', margin:"10px"}}>
                    <ButtonConform 
                        text = "Modifier les informations"
                        style = {{backgroundColor:'#1C546F', width: "176px"}}
                        onClick = {()=> setPageMenu("editlocal", 4)}
                    />
                </div>
                }


            { blockType === "LOINC" && 
                <div style = {{display : 'flex', justifyContent:'flex-end', alignItems:'flex-end', margin:"10px"}}>
                    <ButtonConform 
                        text = "Modifier le transcodage"
                        style = {{backgroundColor:'#1C546F', width: "176px"}}
                        onClick = {switchToEditLOINC}
                    />
                </div>
                }

                { (blockType === "CDA" || blockType === "CR") && !isSpecific && datasDisplay.datas.infos && datasDisplay.datas.infos.Commun &&  Object.entries(datasDisplay.datas.infos.Commun).map((v, k) =>(
                    <DivLineCDA 
                        key = {"divcommlivecda_"+k}
                        datas = {v[1]}
                        globalState = {checkGlobal(v[1])}
                        title = {Object.values(v[1]).length > 1 ? v[0] : ""}
                        onClick = {(state, p, mongokey) => onClick(state,v[0], p, mongokey)}
                    />
                ))}


                { isSpecific  &&
                    <div style = {{display : "flex", flexDirection:"column", height:"92%"}}>
                        <InternalTabulationMenu 
                            tabs = {datasDisplay.datas.metainfo.Specimen}
                            active = {TabActif}
                            onClick = {n => dispatch(setSpecimenTab(n))}
                        />
                        
                        <NeuMorphisme style = {{ display : "flex",  flexDirection : "column", width : '100%', margin : '5px auto', overflow:'auto', minHeight:"50%", backgroundColor: "white"}}  >

                        {TabActif === 0 && Object.entries(datasDisplay.datas.infos.Commun).map((v, k) =>(
                                    <DivLineCDA 
                                    key = {"divlivecda_"+k}
                                    datas = {v[1]}
                                    globalState = {checkGlobal(v[1])}
                                    title = {Object.values(v[1]).length > 1 ? v[0] : ""}
                                    onClick = {(state, p, mongokey) => onClick(state,v[0], p, mongokey)}
                                />
                        ))}

                        {TabActif > 0 && Object.entries(datasDisplay.datas.infos.Specific[TabActif-1]).map((v, k) =>(
                                    <DivLineCDA 
                                    key = {"divlivecda_"+k}
                                    datas = {v[1]}
                                    globalState = {checkGlobal(v[1])}
                                    title = {Object.values(v[1]).length > 1 ? v[0] : ""}
                                    onClick = {(state, p, mongokey) => onClick(state,v[0], p, mongokey)}
                                />
                        ))}
                        </NeuMorphisme>

                    </div>
                }
            </div>
            </NeuMorphisme>
       
   
                <div style = {{display : 'flex', justifyContent:'flex-end', alignItems:'flex-end', margin:"50px 50px 0 0 " }}>
                    {btnEnd && <ButtonConform 
                            text = "Terminer"
                            style = {{backgroundColor:'#54A67B'}}
                            onClick = {()=>{ showEndBtn(false); dispatch(setNavTab(0))}}
                        />
                    }

                    {btnNext && 
                        <ButtonConform 
                        text = "Suivant"
                        onClick = {doNextBtn}
                    />


                    }

                </div>
                
  
   
        </div>
    )



}


