import { createSlice } from "@reduxjs/toolkit";



export const onaosSlice = createSlice({
    name : "onaosProject",
    initialState:{
        open : false, 
    },
    reducers: {
        switchState  : (state, action) => {
            state.open = !state.open
            return state
        },
        closeState : (state,action) => {
            state.open = false
            return state
        }
    }
})


export const {switchState, closeState} = onaosSlice.actions;