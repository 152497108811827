import React, { useEffect, useRef } from 'react'
import { NeuMorphisme, TitleWithoutMargin } from "onaosreact"




export const CDAHead = ({datasDisplay}) => {
    const ref = useRef(null);
    const refHead = useRef(null);

    const thstyle = {
        padding: "0.1cm 0.2cm",
        backgroundColor: '#cbd0dc',
        textAlign: "left",
        borderStyle: "solid hidden solid hidden",
        borderWidth: "1px",
    }

    const tdstyle = {
        padding: "0.1cm 0.2cm",
        textAlign: "left",
        borderStyle: "hidden",
        borderWidth: "1px",
        backgroundColor: '#f7f7f7',

    }

    const tdstyleFirstLine = {
        padding: "0.1cm 0.2cm",
        backgroundColor: '#ccd',
        textAlign: "center",
        borderStyle: "hidden",
        borderWidth: "1px",
    }

    const css = (el, styles) => {
	    for (var property in styles)
	        el.style[property] = styles[property];
	}

    const filter = datasDisplay.file !== undefined ? datasDisplay.file.filter(line => line.head === "") : 0

    useEffect(() => {

        if (filter.length > 1 && datasDisplay.firstLine !== undefined) { //!
            const myDomElement = datasDisplay.firstLine.cloneNode(true);
            myDomElement.style = {}
  
            myDomElement.querySelectorAll('td').forEach((element, line) => {
                css(element, tdstyleFirstLine)
                const spanTr = element.querySelector("div>span")
                if (spanTr !== null) spanTr.remove()
                if (line === 0) element.style.backgroundColor = "rgb(217, 217, 217)"
            });
            ref.current.appendChild(myDomElement);

        }
        if (datasDisplay.lineInfos !== undefined) {
            const resultInfos = datasDisplay.lineInfos.cloneNode(true)
            resultInfos.style = {}
            const spanTr = resultInfos.querySelector("span")
            if (spanTr !== null) spanTr.remove()
            resultInfos.querySelectorAll('td').forEach((element, line) => {
                css(element, tdstyle)
                const span = element.querySelector("div>span")
                if (span !== null) span.remove()
            });
            ref.current.appendChild(resultInfos);
        }
      },[]);


      useEffect(() => {
        if (datasDisplay.headJS !== undefined) {
            const myDomElement = datasDisplay.headJS.cloneNode(true);
            myDomElement.querySelectorAll('th').forEach(element => {
                css(element, thstyle)
            });

            refHead.current.appendChild(myDomElement);
        }
      },[]);



    return(
        <NeuMorphisme style = {{ maxHeight : "30%", display : "flex", flexDirection : "column", width : '95%', margin : '0px auto',overflow : "auto" }}  >
           <div style = {{display : "flex", flexDirection : "column",width : "98%",backgroundColor : "white", marginLeft : 'auto', marginRight : 'auto', borderRadius : '5px', padding : "5px", gap : "8px"}}>
            <TitleWithoutMargin text = "Rappel du compte rendu"/>                  
            <table >
                <thead ref = {refHead}>
                </thead>

                <tbody ref = {ref}>
                </tbody>
            </table>                   
        </div>
    </NeuMorphisme>
    )


}