import React from 'react'
import 'onaosreact/dist/index.css';
import { SearchBar } from './SearchBar'
import { JournalTable } from './JournalTable'


export const Journalisation = (props) => {

  return (

    <div style = {{height : '100%', overflow : 'hidden'}}>

        <SearchBar />
        <JournalTable />

    </div>
  )
}