import { createSlice } from "@reduxjs/toolkit";



export const DictionnaryCodesSlice = createSlice({
    name : "dictionnaryCodes",
    initialState:{
        datas : {
            head : [        
                "Code local", "Libellé", "Unité", "Type de code", "Date d'import"
        ],
            body : []
        }, 
        typeTable : "new",
        linesCheck : [],
        isOneLineCheck : false,
        infos : {}
    },
    reducers: {
        setCodeTableDatas : (state, action) => {
            state.datas = action.payload.datas
            state.typeTable = action.payload.typeTable
            state.infos = action.payload.datas.infos
            return state
        },
        setLinesCheck : (state, action) => {
            state.linesCheck = action.payload.linesCheck
            state.isOneLineCheck = action.payload.linesCheck.includes(true)
            return state
        }
        
    }
})


export const {setCodeTableDatas, setLinesCheck} = DictionnaryCodesSlice.actions;