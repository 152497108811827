import { all } from 'axios'
import { ButtonConform, ButtonNonConform, ButtonSnackBarConfirm, ButtonSnackBarDismiss, ConfirmationSnackBar, DivLineCDA, NeuMorphisme, TitleWithoutMargin } from 'onaosreact'
import React from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { putAPI } from '../../../api/api'
import { cancelEdit, openEditCode, setCodeInfos, setNavMenu, setNavMenusState } from '../../../redux/scenario/scenario'
import { setClientLocalInfos, setClientLOINCInfos, showSearch } from '../../../redux/search/search'
import { useSnackbar } from 'notistack'


export const CDAEdit = (props) => {

   
    const datasDisplay = useSelector(state => state.scenario.codeInfos)
    const scenarioID = useSelector(state => state.scenario.scenarioID)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const lineID = useSelector(state => state.scenario.codeInfos.datas.metainfo.id)

    const dispatch = useDispatch()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const onClick = (type, block, item) => {
        const ID = datasDisplay.datas.metainfo.id 
        closeSnackbar()
        if (type === "cancelEdit") {
            const dataEdit = {
                item : item,
                codeIDCDA : ID,
                scenarioID : scenarioID,
                codeID : datasDisplay.datas.metainfo.idlocal,
                action : "cancel"
            }
            putAPI("/editCode",tokenRefresh , dataEdit).then(data => {
                if (data.ok) {
                    dispatch(cancelEdit({
                        item : item,
                    }))
                }
            })
        } else {
            if (item !== "Code LOINC"){
                dispatch(openEditCode({
                    item : item,
                    itemValue :  datasDisplay.datas.infos.Commun[block][item].text[0],
                    codeIDCDA : ID,
                    scenarioID : scenarioID,
                    codeID : datasDisplay.datas.metainfo.idlocal,
                }))
            } else {
                putAPI('/formatSearchClientData', tokenRefresh, datasDisplay.datas.infos.Commun).then(data => {
                    batch(()=>{
                        dispatch(setClientLOINCInfos(data.loinc))
                        dispatch(setClientLocalInfos(data.local))
                        dispatch(showSearch({open : true}))
                    })
                })
            }
        }

    }

    const setPageMenu = (valItem, n) => {
        const clickDatas = {
            scenarioID : scenarioID,
            line : lineID,
            action: valItem,
        }

        putAPI("/idinfos", tokenRefresh, clickDatas).then(data => {
            if (data.datas && data.datas.metainfo ){
                batch(()=> {
                    dispatch(setCodeInfos({datas : data.datas}))
                    dispatch(setNavMenu(n))
                    dispatch(setNavMenusState(data.itemState))
                })
            }

        })
    }

    const action = snackbarId => (
        <ConfirmationSnackBar
            selectConfirm = {() => { onClick("cancelEdit", "", "all"); closeSnackbar(snackbarId)}}
            selectDismiss = {() => { closeSnackbar(snackbarId) }}
        />
      );

    return(
            <NeuMorphisme style = {{ height : "100%", display : "flex", flexDirection : "column", width : '95%', margin : '0px auto', }}  >   
                <div style = {{display : "flex", flexDirection : "column",width : "98%",backgroundColor : "white", marginLeft : 'auto', marginRight : 'auto', borderRadius : '5px', padding : "5px", gap : "8px", overflow:'auto', height:"100%"}}>

                    {datasDisplay.datas && datasDisplay.datas.infos && datasDisplay.datas.title && 
                        <div style = {{display : 'flex', width : "90%" }}>
                            {datasDisplay.datas.title.map (title => (
                                <div style = {{display : 'flex', width : "50%"}}>
                                        <TitleWithoutMargin text = {title} />
                                </div>
                            ))}
                        </div>                      
                    }
                    {datasDisplay.datas.infos && datasDisplay.datas.infos.Commun &&
                        <NeuMorphisme style = {{ display : "flex",  flexDirection : "column", width : '100%', margin : '5px auto', overflow:'auto'}}  >
                                {Object.entries(datasDisplay.datas.infos.Commun).map((v, k) =>(
                                    <DivLineCDA 
                                        key = {"divlivecda_"+k}
                                        datas = {v[1]}
                                        globalState = ""
                                        title = {Object.values(v[1]).length > 1 ? v[0] : ""}
                                        onClick = {(state, p) => onClick(state,v[0], p)}
                                        doubleTitle = {true}
                                    />
                                ))}
                        </NeuMorphisme>
                    }
                    {datasDisplay.datas.infos === undefined && 
                         <TitleWithoutMargin text = "Information indisponible" style = {{textAlign : "center"}} />
                    } 
                    <div style = {{display : 'flex', justifyContent:'flex-end', margin:'10px'}}>
                            <ButtonNonConform
                                text = "Annuler toutes les modifications"
                                style = {{width: "215px"}}
                                onClick = {() => enqueueSnackbar("Annuler tous les changements ?", {
                                action,
                                variant: 'warning',
                                autoHideDuration: 3000
                            })}
                            />
                            <ButtonConform 
                                text = "Retourner au code local"
                                style = {{backgroundColor:'#1C546F', width: "176px"}}
                                onClick = {()=> setPageMenu("Local", 2)}
                            />
                    </div>
                </div>
            </NeuMorphisme>

    )



}


