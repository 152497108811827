import { useSelector } from "react-redux"
import { AddSGL } from "./AddSGL"
import { HeadNavSGL } from "./HeadNavSGL"
import { SGLTable } from "./SGLTable"


export const DashSGL = (props) => {
    const sglOpen = useSelector(state => state.sgl.open)

     return(
        <div style = {{height : '100%', overflow : 'hidden'}}>
            {sglOpen && <AddSGL />}
            <HeadNavSGL />
            <SGLTable />
     </div>
    )


}