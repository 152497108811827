import { createSlice } from "@reduxjs/toolkit";



export const DashBoardMenuSlice = createSlice({
    name : "DashBoardMenu",
    initialState:{
        page : ["Mon SGL" , "sgl"], 
    },
    reducers: {
        setPage : (state, action) => {
            state.page = action.payload

            return state
        },
        
    }
})


export const {setPage} = DashBoardMenuSlice.actions;