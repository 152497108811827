import { DashBoardTable, SubMenu, ThreeDotMenu } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPI } from '../../../api/api'
import { defineSGLCheck } from '../../../redux/sgl/sgl'


export const SGLTable = (props) => {


    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const loadSGL = useSelector( state => state.sgl.list)
    const dispatch = useDispatch()

    const defineDefault = (line) => {
        //send info to API
        getAPI("/definesgl/"+loadSGL.infos[line].id, tokenRefresh).then(
            dispatch(defineSGLCheck(line))
        )
    }



    return(
        <div style = {{display : 'flex', flexDirection:'column', justifyContent:'left', margin : '20px',}}>
            <div style = {{height:'75vh'}}>
                <DashBoardTable
                    head = {loadSGL.head}
                    body = {loadSGL.body}
                    radioButton = {true}
                    lineCheck = {loadSGL.datas.selected}
                    onClick =    {(line) => defineDefault(line)}
                    tableStyle = {{tableLayout  :"fixed"}}
                />
            </div>
        </div>
            
    )


}
