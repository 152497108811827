import { ButtonBluePlus, InputSearchDash } from 'onaosreact'
import React  from 'react'
import { useDispatch } from 'react-redux'
import { openDictionaries } from '../../../redux/dictionnary/dictionnary'




export const HeadNavImport = (props) => {

    const dispatch = useDispatch() 

    const searchBar = {
        display : "flex",
        width : "100%",
        margin : "10px auto 20px 20px",
        alignItems : "center"

      }
    
      const openDict = () => {
        dispatch(openDictionaries())
      }

     return(
        <div style = {searchBar}>
            <ButtonBluePlus 
                text = "Importer des fichiers"
                onClick = {openDict}
                style = {{width : '180px'}}
            />
            {/* <InputSearchDash
                placeHolder = "Rechercher des éléments dans vos dictionnaires"
                style = {{marginLeft : "100px"}}
            /> */}
      </div>
    )



}