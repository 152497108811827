
import {  useWindowSize} from "onaosreact"
import { useSelector } from "react-redux"
import  {CDACheck} from "./CDACheck"
import { CDAEdit } from "./CDAEdit"




export const MainContainerCheck = (props) => {
    
    const menu = useSelector(state=>state.scenario.nav.menu)
    const windowSize = useWindowSize()

    const withSize = () => {
        const multi = window.devicePixelRatio
          if  (windowSize.width<=1600) return 1200/multi + "px"
          if  (windowSize.width<=1920) return 1500/multi + "px"
          if  (windowSize.width>1920 ) return 2100/multi+"px"
      }


    const styleDiv = {
        width:withSize(),//"1430px",
        backgroundColor : "#FFFFFF",    
        display : "flex",
        flexDirection : 'column',
        gap : "16px",
        height: "100%"
    }


    return(
        <div  style = {styleDiv}>
            {menu === 0 && <CDACheck blockType = "CR" />}
            {menu === 1 && <CDACheck blockType = "CDA"/>}
            {menu === 2 && <CDACheck blockType = "Local"/>}
            {menu === 3 && <CDACheck blockType = "LOINC"/>}
            {menu === 4 && <CDAEdit />}
        </div>
    )


}