import { NavBar, useWindowSize } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unLog } from '../redux/connection/log'
import { setPage } from '../redux/dashboardmenu/DashBoardMenu'

import {useNavigate} from "react-router-dom";
import { getAPI, putAPI } from '../api/api'
import { useSnackbar } from 'notistack'


import { fetchCodes, fetchReport } from "../redux/journal/journalDatas";
import { fetchDictionaries } from "../redux/dictionnary/dictionnary";
import { fetchScenarios } from "../redux/UserDatas/UserDatas";
import { fetchSGL } from "../redux/sgl/sgl";



export const MenuViewer = (props) => {

  const email = useSelector( state => state.log.email)
  const user = useSelector( state => state.log.user)

  const oanosProject = useSelector( state => state.log.oanosProject)
  const tokenRefresh = useSelector (state => state.log.tokenRefresh)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const windowSize = useWindowSize()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadDatasJournal = (page) => {
    getAPI("/allcodesalldictionnary", tokenRefresh).then(data =>{
        dispatch(fetchCodes({codes : data}) )
        dispatch(setPage(page))
    })
  }

  const loadDatasReport = (page) => {
      enqueueSnackbar("Recherche de vos codes en cours", {variant: 'info'})
      getAPI("/loadallcodes", tokenRefresh).then(data =>{
          closeSnackbar()
          dispatch(fetchReport({datas : data}) )
          dispatch(setPage(page))
      })
  }


  const loadDicto = (page) => {
      getAPI("/dictionnaries",tokenRefresh).then( data => {
          dispatch(fetchDictionaries({list : data}))
          dispatch(setPage(page))
      })
  }

  const loadScenar = (page) => {
      getAPI("/allscenarios",tokenRefresh).then( data => {
          if (data.head !== undefined)  dispatch(fetchScenarios({scenarios : data}))
          dispatch(setPage(page))
      })
  }

  const loadSGL = (page) => {
      getAPI("/sgls", tokenRefresh).then(data => {
          dispatch(fetchSGL({list : data}))
          dispatch(setPage(page))
        })
  }


  const setPageMenu = (page) => {

    switch(Object.entries(page)[1][1]){
        case 'journal':
            loadDatasJournal(page)
            break
        case 'import':
            loadDicto(page)
            break
        case 'report':
            loadDatasReport(page)
            break
        case 'scenar':
            loadScenar(page)
            break
        case 'sgl':
            loadSGL(page)
            break
        default:
            dispatch(setPage(page))
            break
    }
  }


  const openDashboard = (id, params) => {
    
    // dispatch(setPage(params))
    setPageMenu(params)
    navigate("/dashboard");    
  }

  const openExit = () => {
    dispatch(unLog())
  }

  const goto = (id, destination) => {
    const data = {
        destination : destination,
    }
    putAPI("/redirect", tokenRefresh, data).then(data => {
        if (data.url !== undefined) {
            setTimeout(() => window.open(data.url, '_blank'), 200);
        } else {
            setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + "/?r=" + destination, '_blank'), 200);
        }
    } )
}



  const MenuData = [
      {label : "Mon SGL", click : openDashboard, params : ["Mon SGL" , "sgl"]},
      {label : "-"},
      {label : "Mon dictionnaire", click : openDashboard, params : ["Mon dictionnaire" , "import"]},
      {label : "-"},
      {label : "Mes scénarios", click : openDashboard, params : ["Mes scénarios" , "scenar"]},
      {label : "-"},
      {label : "Traçabilité",  click : openDashboard, params : ["Traçabilité" , "journal"]},
      {label : "-"},
      {label : "Conformité",  click : openDashboard, params : ["Rapport de conformité" , "report"]},
      {label : "-"},
      {label : "Quitter",  click : openExit},
  ]


  const getOnaosProject = () => {
    let data = []
    oanosProject.map(v => 
      data.push({label : v.projet.charAt(0).toUpperCase() + v.projet.slice(1), click : goto, params : v.projet})
    )
    return data  
  }

  const subMenuAccount = [
    {label : "Mon compte",},
  ]


  const openOnaosWebSite = () => {
    setTimeout(() => window.open('https://www.onaos.com', '_blank'), 200);

  }


  return (
      <NavBar 
          projectName = {windowSize.width <1000 ? 'CDA' : "CDA Check"} 
          menuDatas = {MenuData}
          menuOnaosDatas = {getOnaosProject()}
          menuAccountDatas = {subMenuAccount} 
          projectClick  = {() => openDashboard(0,["Mes scénarios" , "scenar"])}
          onaosClick = {openOnaosWebSite}
          user = {user}
          email = {email}
      />

  )


}