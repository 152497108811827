import React from 'react'
import 'onaosreact/dist/index.css';
import { SearchBar } from './SearchBar'
import { ReportTable } from './ReportTable'


export const Report = (props) => {

  return (

    <div style = {{height : '100%', overflow : 'hidden'}}>

        <SearchBar />
        <ReportTable />

    </div>
  )
}