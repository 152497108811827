import React, {useEffect, useState, useRef} from "react"
import {  NeuMorphisme, TabulationMenu} from 'onaosreact'

import { batch, useDispatch, useSelector } from 'react-redux'
// import myxml from './pau.xml'
import { MainCheck } from "./Check/MainCheck"
import { internalDiv } from "../../styles/internalDiv"
import {putAPI} from '../../api/api.js'
import { setCodeInfos, setNavMenu, setNavMenusState, setNavTab } from "../../redux/scenario/scenario"
import { CDAFile } from "./CDA/CDAFile"
import { useSnackbar } from "notistack"


export const CDA = (props) => {
    const TabActif = useSelector(state => state.scenario.nav.tab)
    const IDConform = useSelector(state => state.scenario.codesState)
    const IDsList = useSelector(state => state.scenario.IDsList)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const isOneLineClick = useSelector(state => state.scenario.codeInfos.datas)

    const dispatch = useDispatch()
    const iframeRef = useRef(null);
    let isClicked = false;

    const myxml = useSelector(state => state.scenario.xml)
    
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const scenarioID = useSelector(state => state.scenario.scenarioID)
    
    const [isLoad, setLoad] = useState(false) 

    const request = (id, dd) => {
        const clickDatas = {
            scenarioID : scenarioID,
            line : id,
            action:'loadfirstunfinish'
        }
        putAPI("/idinfos", tokenRefresh, clickDatas).then(data => {
            batch(()=> {
                dispatch(setCodeInfos({datas : data.datas, file : dd.line, firstLine : dd.firstLine, headJS : dd.headJS,lineInfos : dd.lineInfos }))
                const item = data.nav ? data.nav : 0
                dispatch(setNavMenu(item))
                dispatch(setNavMenusState(data.itemState))
                dispatch(setNavTab(1))
            })

        })
    }

    const onClickLine = (ID, lineInfos, head, position, firstLineBody) => {
        if (!isClicked) {
            isClicked = true;            
            const tds = lineInfos.querySelectorAll('td')
            const ths = head?.querySelectorAll("th")
            // const bodyOneLine = firstLineBody.querySelectorAll("td")
            // const bodyOneLineTd =  Array.from(bodyOneLine).map((v,k) => {
            //     return v.innerText
            // })
            // console.log(bodyOneLineTd)

            const dd = Array.from(tds).map((v,k) => {
                const text = v.innerText.replace("radio_button_unchecked", "").replace("check_circle", "").replace("cancel", "").replace("flaky", "")
                return ths !== undefined && ths[k] !== undefined ? 
                    {text : text,head : ths[k].innerText, id : ID } 
                    : 
                    {text : text,head : "", id : ID} 
            })

            sessionStorage.setItem('scrollPosition', position)
            request(ID, {line : dd, firstLine : firstLineBody,lineInfos : lineInfos,  headJS : head?.querySelector('tr')})
            setTimeout(() => {
              isClicked = false;
            }, 500);
        }
    }

    const loadFrame = (iframe) => {
        const scrollPosition = sessionStorage.getItem('scrollPosition');
        if (scrollPosition) {
            iframe.contentWindow.scrollTo(0, parseInt(scrollPosition, 10));
            sessionStorage.removeItem('scrollPosition');
        } else {
            iframe.contentWindow.scrollTo(0, 0);
        }

        const doc = iframe.contentDocument;
        const head = doc.querySelector("head")
        const link = doc.createElement("link")
        link.rel = "stylesheet"
        link.href ="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
        head.appendChild(link)
        const lines = doc.querySelectorAll('tr');



        lines.forEach(line => {
            const div = line.querySelector('td:first-child>div');
            const ID = div !== null ? div.getAttribute('id') : ''
            const old = doc.getElementById("spanonaos_"+ID)
            if (old !== null) old.remove()
            const icon = doc.createElement('span')
            if (ID !== '' && IDsList[ID] === undefined) return; 
            if (ID !== ''){
                icon.setAttribute('id',"spanonaos_"+ID )
                icon.className = "material-symbols-outlined"
                if (IDConform[ID] === undefined || IDConform[ID] === 'tocheck') icon.innerHTML = "radio_button_unchecked"
                if (IDConform[ID] !== undefined && IDConform[ID] === 'valid') {
                    icon.innerHTML = "check_circle"
                    icon.style.color = "rgb(84,166,123)"
                }
                if (IDConform[ID] !== undefined && IDConform[ID] === 'reject') {
                    icon.innerHTML = "cancel"
                    icon.style.color = "rgb(196,60,60)"
                }
                if (IDConform[ID] !== undefined && IDConform[ID] === 'inprogress') {
                    icon.innerHTML = "flaky"
                    icon.style.color = "#D7872F"
                }

                line.appendChild(icon)


                if (!isLoad) {
                    line.addEventListener('click', event => {        
                        if (ID !== null && ID !== undefined && ID !== "") {
                            const ths = event.currentTarget.parentNode.parentNode.querySelector('thead')
                            const firstLineBody = event.currentTarget.parentNode.querySelector('tbody>tr:first-child')
                            const position = iframe?.contentWindow?.pageYOffset
                            line.querySelectorAll('td').forEach(cell => {
                                if (cell.dataset.backgroundColor !== undefined)  cell.style.backgroundColor =  cell.dataset.backgroundColor 
                            })

                            line.querySelectorAll('span').forEach(cell => {
                                cell.style.backgroundColor =  'transparent'
                            })


                            onClickLine(ID, line, ths, position, firstLineBody)
    
                        }
                    })
                    line.addEventListener('mouseover', event => {
                        const div = event.currentTarget.querySelector('td:first-child>div');
    
                        if (div !== null && div !== undefined ){
                            const ID = div.getAttribute('id')
                            if (ID !== null && ID !== undefined && ID !== "") {
                                line.style.cursor = 'pointer';
                                line.style.backgroundColor = "#b0f2b6";
                                line.querySelectorAll('td').forEach(cell => {
                                    cell.dataset.backgroundColor = cell.style.backgroundColor;
                                    cell.style.backgroundColor = 'transparent';
                                })
                            }
                        }
                    })
                    line.addEventListener('mouseout', () => {
                        line.style.cursor = 'default'
                        line.style.backgroundColor = "transparent"
                        line.querySelectorAll('td').forEach(cell => {
                            if (cell.dataset.backgroundColor !== undefined) {
                                cell.style.backgroundColor =  cell.dataset.backgroundColor 
                                cell.dataset.backgroundColor = undefined
                            } 
                        })
                    })
                
                }


            } else {
                    line.querySelectorAll('td').forEach(cell => {
                        const divTd = cell.querySelector('div');
                        if (divTd !== null) {
                            const cellID = divTd.getAttribute('id')
                            if (cellID !== null && IDsList[cellID] === undefined) return; 
                            if (cellID !== null && cellID !== undefined && cellID !== "") {
                                divTd.style.display = "flex"
                                divTd.style.alignItems = "center"
                                divTd.style.justifyContent = "center"
                                divTd.style.gap = "4px"

                                const oldSpan = divTd.querySelector("span[id = 'spanonaos_"+cellID+"'")
                                if (oldSpan !== null) oldSpan.remove()
                                    const icone2 = doc.createElement('span')
                                    icone2.setAttribute('id',"spanonaos_"+cellID )
                                    if (IDConform[cellID] === undefined || IDConform[cellID] === 'tocheck') icone2.innerHTML = "radio_button_unchecked"
                                    if (IDConform[cellID] !== undefined && IDConform[cellID] === 'valid') {
                                        icone2.innerHTML = "check_circle"
                                        icone2.style.color = "rgb(84,166,123)"
                                    }
                                    if (IDConform[cellID] !== undefined && IDConform[cellID] === 'reject') {
                                        icone2.innerHTML = "cancel"
                                        icone2.style.color = "rgb(196,60,60)"
                                    }
                                    if (IDConform[cellID] !== undefined && IDConform[cellID] === 'inprogress') {
                                        icone2.innerHTML = "flaky"
                                        icone2.style.color = "#D7872F"
                                    }
                                    icone2.className = "material-symbols-outlined"
                                    divTd.appendChild(icone2)


                                    if (!isLoad) {
                                        cell.addEventListener('click', event => {        
                                            if (cellID !== null && cellID !== undefined && cellID !== "") {
                                                const ths = event.currentTarget.parentNode.parentNode.parentNode.querySelector('thead')
                                                const firstLineBody = event.currentTarget.parentNode.parentNode.querySelector('tbody>tr:first-child')
                                                const position = iframe?.contentWindow?.pageYOffset
                                                    if (cell.dataset.backgroundColor !== undefined)  cell.style.backgroundColor =  cell.dataset.backgroundColor 
                                                onClickLine(cellID, line, ths, position, firstLineBody)
                                            }
                                        })
                                        cell.addEventListener('mouseover', event => {
                                                if (cellID !== null && cellID !== undefined && cellID !== "") {
                                                    cell.style.cursor = 'pointer';
                                                    cell.dataset.backgroundColor = cell.style.backgroundColor;
                                                    cell.style.backgroundColor = '#b0f2b6';
                                                }
                                        })
                                        cell.addEventListener('mouseout', () => {
                                            cell.style.cursor = 'default'
                                            if (cell.dataset.backgroundColor !== undefined) {
                                                 cell.style.backgroundColor =  cell.dataset.backgroundColor 
                                            } else {
                                                cell.style.backgroundColor = "transparent"
                                            }
                                        })
                                    }
                                
                            }
                        }

                    })
                
            }

        });
        closeSnackbar()
    }


    useEffect(() => {        
        if (!isLoad && TabActif === 0){
            const iframe = iframeRef.current;
            if (iframe === null) return;
            enqueueSnackbar("Fichier en cours de chargement", {variant: 'info'})
            iframe.addEventListener('load', () => {
                loadFrame(iframe)
                setLoad(true)
            })
        }
    }, []);


    useEffect(() => {        
        if (isLoad && TabActif === 0 ){
            closeSnackbar()
            const iframe = iframeRef.current;
            if (iframe === null) return;
            loadFrame(iframe)
        }
    }, [TabActif, isLoad]);




    const pageStyle = {
        height : "85vh", 
        width : "100%",
        marginLeft : 'auto', 
        marginRight : 'auto',
        padding : "10px",
        backgroundColor:'#FFFFFF',
        boxShadow : "",
        borderRadius : TabActif === 0 ? "0px 5px 5px 5px" : "5px",
    }

    const setTab = (n) => {
        dispatch(setNavTab(n))
    }

    const getTabText = isOneLineClick.metainfo !== undefined ? [ 
            {title : "Compte rendu CDA" }, 
            // {title : "Vérification de l'entête"},
            {title : "Vérification d'une analyse" },
        ] : [ 
            {title : "Compte rendu CDA" }, 
        ]


    return(
        <div style={internalDiv.mainContainerStyle}>
            <TabulationMenu 
                tabs = {getTabText}
                active = {TabActif}
                onClick = {setTab}
            />
            <NeuMorphisme style = { pageStyle }>
                { myxml !== "" && 
                    <div 
                        style = {{display : TabActif === 0 ? 'flex' : "none", width : '100%', height:'100%', flexDirection:'column'}}
                    >
                        <CDAFile 
                            url = {myxml}
                            iframeRef = {iframeRef}
                        />
                    </div>
                }

                {TabActif === 1 && <MainCheck />}
            </NeuMorphisme>
           
        </div>
    )



}