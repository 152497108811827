import { createSlice } from "@reduxjs/toolkit";



export const userdatasSlice = createSlice({
    name : "userDatas",
    initialState:{
        scenarios : {
            head : [
                'Nom','Créé le',"Modifié le", "Expert", "Dictionnaire Résultats", "Dictionnaire Demandes",
                'Terminé le','Statut', ''
            ],
            body :[],
            bodyInfos : []
        },
        scenarioRender : false,
    },
    reducers: {
        fetchScenarios  : (state, action) => {
            state.scenarios.head = action.payload.scenarios.head
            state.scenarios.body = action.payload.scenarios.body
            state.scenarios.bodyInfos = action.payload.scenarios.donotshow
            state.scenarioRender = true
            return state
        },
        turnOffRenderScenario : (state, action) => {
            state.scenarioRender = false
            return state
        },
        deleteScenario : (state, action) => {
            state.scenarios.body.splice(action.payload.position,1)
            return state
        },
        renameScenario : (state , action )=> {
            state.scenarios.body[action.payload.position][0] = action.payload.newName
            // state.scenarios.body.forEach((element,i) => {
            //     if (element[0] === action.payload.oldName) {
            //         state.scenarios.body[i][0] =  action.payload.newName
            //     }
            // })
            return state
        }

    }
})


export const {fetchScenarios, turnOffRenderScenario, renameScenario, deleteScenario} = userdatasSlice.actions;