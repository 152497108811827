import { useSnackbar } from 'notistack'
import { ButtonBluePlus } from 'onaosreact'
import React from 'react'
import { useSelector } from 'react-redux'
import { putAPI } from '../../../api/api'



export const SearchBar = (props) => {

    const reportDatasSelected = useSelector(state => state.journalDatas.codesSelected)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const searchBar = {
        display : "flex",
        width : "100%",
        margin : "10px 0 20px 10px",
        alignItems : "center",
        justifyContent : 'left',

    }


    const sortData = (colIndex, asc = true) => {
        let a = [...reportDatasSelected.donotshow]
        const sortFunction = (a, b) => {
            if (a[colIndex] === b[colIndex]) {
                return 0;
            }
            else {
                if (asc) return (a[colIndex] < b[colIndex]) ? -1 : 1; else return (a[colIndex] > b[colIndex]) ? -1 : 1;
            }
        }
        a.sort(sortFunction)
        return a
    }


    const downloadReport = () => {
        if ( reportDatasSelected.body.length === 0) {
            enqueueSnackbar("Veuillez sélectionner au moins un code local", { 
                variant: 'error',
            }) 
            return
        }
        enqueueSnackbar('Votre rapport est en cours de génération, merci de patienter', { 
            variant: 'info',
            persist: true,
        }) 
        const datas = sortData("id",false)
        putAPI("/makereport", tokenRefresh,datas, "blob").then (data => {
            if (data) {
                // enqueueSnackbar('Fichier disponible', {variant: 'success'})
                closeSnackbar()
                const urld = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = urld;
                link.title = "Rapport";
                link.setAttribute('download', "rapport de conformite.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(urld);
            } else {
                enqueueSnackbar("Une erreur s'est produite : "+data.error, {variant: 'error'})
            }
        })

    }

    return (
        <div style={searchBar} >
            <ButtonBluePlus 
                text = "Générer un rapport de conformité"
                onClick = {downloadReport}
                removeAddIcon = {true}
                style = {{backgroundColor : reportDatasSelected.body.length > 0 ? '#1C546F' : '#707070'}}
            />
        </div>
    )
}