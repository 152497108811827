import React from 'react'
import { MainContainerCheck } from './MainContainerCheck'
import { MenuCheck } from '../Menu/MenuCheck'
import { useSelector } from 'react-redux'
import { CodeEdition } from '../Edition/CodeEdition'








export const MainCheck = () => {
  
    const openEdit = useSelector(state => state.scenario.isOpenEditCode)


    return(
        <div style = {{display : "flex",padding : "30px", gap :'40px', height : "88%" }}>
            {openEdit && <CodeEdition />}
            <MenuCheck/>
            <MainContainerCheck />
            
        </div>
    )



}