import React , {useEffect} from 'react';
import { useDispatch } from 'react-redux';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,  
  
} from "react-router-dom";

import { useSelector} from 'react-redux';

import './App.css';
import { Dashboard } from './dashboard/dashboard';
import { getAPI, putAPI } from './api/api';
import { setToken } from './redux/connection/log';

import { closeState } from './redux/onaosprojects/onaosprojects';
import { Scenarios } from './scenarios/Scenarios';
import { MenuViewer } from './menu/MenuViewer';
import { fetchDictionaries } from './redux/dictionnary/dictionnary';
import { fetchSGL } from './redux/sgl/sgl';
import { setPage } from './redux/dashboardmenu/DashBoardMenu';
import { fetchScenarios } from './redux/UserDatas/UserDatas';


export const App = ()=> {

  const log = useSelector( (state) =>state.log ) 
  const dispatch = useDispatch()
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let t = params.get('t');

  let sso = params.get('sso');

  const doNavigation = (who, tokenRefresh) => {
    switch (who) {
      case "import" : 
        getAPI("/dictionnaries", tokenRefresh).then(data => {
          dispatch(fetchDictionaries({list : data}))
          dispatch(setPage( {"Mon dictionnaire" : "import"}))
        })
        break
      case "scenar":
        getAPI("/allscenarios",tokenRefresh).then( data => {
          if (data.head !== undefined)  dispatch(fetchScenarios({scenarios : data}))
          dispatch(setPage( {"Mes scénarios" : "scenar"}))
      })
        break
      default:
        getAPI("/sgls", tokenRefresh).then(data => {
          dispatch(fetchSGL({list : data}))
        })
        break
    }
  }
  
  useEffect( () => {

    if (t !== null && t !== "" && t!== undefined && !log.connect ) {

      const data = {
        token : t,
      }
 
      putAPI("/checkredirect", "", data).then(data => {
        if ( data.token !== undefined && data.token !== ""){
          dispatch(setToken(data))
          getAPI("/navigation", data.tokenRefresh).then(who => {
            doNavigation(who, data.tokenRefresh)
          })
        } else {
          setTimeout(() => window.open( process.env.REACT_APP_URL_SSO_FRONT  + "/?r=" + process.env.REACT_APP_PROJET_NAME, '_self'), 200);
        }
        })
      }

      if ( t === null && !log.connect && (sso === undefined || sso === null)) {
        setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + "/?r=" + process.env.REACT_APP_PROJET_NAME, '_self'), 200);
      }

      
      if (sso !== undefined && sso !== null && !log.connect && t === null  ) {

        const data = {
          token : sso,
          service : process.env.REACT_APP_PROJET_NAME
        }
       
        putAPI("/checktoken", "", data).then(data => {
          if (data.token !== undefined && data.token !== null && data.token !== ""){
            dispatch(setToken(data))
            getAPI("/navigation", data.tokenRefresh).then(who => {
              doNavigation(who, data.tokenRefresh)
            })

          } else {
            setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT+ "/?r=" + process.env.REACT_APP_PROJET_NAME, '_self'), 200);
          }
          
        })
      }
  })


  return (


    <div className="App" >
        <div style = {{display : 'flex', flexDirection : 'column'}}> 
        <Router>
            <Routes>
              {log.connect && <Route exact path = "/dashboard" element = { <Dashboard /> } /> }
              {log.connect &&  <Route exact path = "/scenarios" element = { <Scenarios /> } /> }
              {log.dashboard && log.connect && 
                <Route 
                  path = "*"
                  element = {<Navigate replace to = "/dashboard" />}
                /> 
              }
            </Routes>
          </Router>
   
          </div>
    </div>
  )
}


