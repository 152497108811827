import { createSlice } from "@reduxjs/toolkit";



export const scenarioSlice = createSlice({
    name : "scenario",
    initialState:{
        open : false, 
        name:"",
        newName:"",
        // oldName:"",
        scenarioSelectPosition : -1,
        openRename : false,
        scenarioExpert : '',
        id : "",
        userID : "",
        xml : "",
        scenarioID:"",
        codeInfos:{
            file : undefined,
            datas : {
            },
        },
        codesState : [],
        nav: {
            tab : 0,
            menu : 0,
        },
        navIteamState : {"CR" : "en cours"},
        IDsList: [],
        isOpenEditCode : false,
        editCodeInfos : {
            item : "",
            value : "",
            itemValue : "",
            ID : "",
            scenarioID : "",
        }
    },

    reducers: {
        openScenario  : (state, action) => { //open window for new name
            state.open = true
            return state
        },
        
        closeScenario : (state,action) => { //close window for new name
            state.open = false
            state.openRename = false
            return state
        },

        openEditCode  : (state, action) => { 
            state.isOpenEditCode = true
            state.editCodeInfos = action.payload
            state.editCodeInfos.value = ""
            return state
        },
        
        closeEditCode : (state,action) => {
            state.isOpenEditCode = false
            return state
        },


        setName : (state,action) => { //save new name
            state.name = action.payload.infos.nom
            state.id = action.payload.infos.id
            state.scenarioExpert = action.payload.infos.expert
            state.userID = action.payload.infos.user
            return state
        },
        openRename : (state, action) => {
            state.openRename = true
            state.scenarioSelectPosition = action.payload.position
            // state.oldName =  action.payload.oldName
            return state
        },
        setNewName : (state, action) => {
            state.newName = action.payload.name
            // state.id = action.payload.id
            return state
        },

        setXML : (state, action) => {
            state.xml = action.payload
            return state
        },
        setScenarioID : (state, action) =>{
            state.scenarioID = action.payload
            return state
        },
        setCodeInfos : (state, action) => {
            if (action.payload.file !== undefined)  state.codeInfos.file = action.payload.file
            if (action.payload.datas !== undefined)  state.codeInfos.datas = action.payload.datas
            if (action.payload.headJS !== undefined)  state.codeInfos.headJS = action.payload.headJS
            if (action.payload.lineInfos !== undefined)  state.codeInfos.lineInfos = action.payload.lineInfos
            if (action.payload.firstLine !== undefined)  state.codeInfos.firstLine = action.payload.firstLine

            return state
        },
        eraseCodeInfos : (state, action) => {
            if (state.codeInfos.datas !== undefined) state.codeInfos.datas.metainfo = undefined
            return state
        },
        setNavMenu : (state, action) => {
            state.nav.menu = action.payload
            return state
        },
        setNavTab : (state, action) => {
            state.nav.tab = action.payload
            return state
        },
        onClickCDALine : (state, action) => {
            const item =  action.payload.item
            const block =  action.payload.block
            const newState = action.payload.state
            if (action.payload.specimen > 0) {
                state.codeInfos.datas.infos["Specific"][action.payload.specimen-1][block][item].state = newState
            } else {
                state.codeInfos.datas.infos["Commun"][block][item].state = newState
             }
            return state
        }, 


        onClickCDALineChange : (state, action) => {
            const n = action.payload.specimen - 1 
            const value = action.payload.state
            if (action.payload.specimen >0) {
                if (n >= 0 && n <state.codeInfos.datas.infos.Specific.length) {
                    Object.keys(state.codeInfos.datas.infos.Specific[n]).forEach((key) => {
                        Object.keys(state.codeInfos.datas.infos.Specific[n][key]).forEach((key2) => {
                            if (state.codeInfos.datas.infos.Specific[n][key][key2].state !== "force" && state.codeInfos.datas.infos.Specific[n][key][key2].state !== "notshown") {
                                state.codeInfos.datas.infos.Specific[n][key][key2].state = value;
                            }
                        });
                    })
                }
            } else {
                Object.keys(state.codeInfos.datas.infos.Commun).forEach((key) => {
                    Object.keys(state.codeInfos.datas.infos.Commun[key]).forEach((key2) => {
                        if (state.codeInfos.datas.infos.Commun[key][key2].state !== "force" && state.codeInfos.datas.infos.Commun[key][key2].state !== "notshown") {
                            state.codeInfos.datas.infos.Commun[key][key2].state = value;
                        }
                    })
                });
            }
            return state
        }, 



        setCodesState : (state, action ) => {
            state.codesState = action.payload
            return state
        },
        setCodeState : (state, action) => {
            state.codesState[action.payload.id] = action.payload.state
            return state
        }, 

        setIDsList : (state, action) => {
            state.IDsList = action.payload
            return state
        },
        setNavMenusState : (state, action) => {
            state.navIteamState = action.payload
            return state
        },
        setNavMenuState : (state, action) => {
            state.navIteamState[action.payload.item] = action.payload.value
            return state
        },
        setSpecimenState : (state, action) => {
            state.codeInfos.datas.metainfo.Specimen[action.payload.index].state = action.payload.state
            return state
        },
        setSpecimenTab : (state, action) => {
            state.codeInfos.datas.specimenIndex = action.payload
            return state
        },
        cancelEdit : (state, action) => {
            const item = action.payload.item
            if (item === "all") {
                const allItems = Object.keys(state.codeInfos.datas.infos["Commun"])
                allItems.forEach(element => {
                    state.codeInfos.datas.infos["Commun"][element][element].state = "edit"
                    state.codeInfos.datas.infos["Commun"][element][element].text[1] = "-"
                })
            } else {
                state.codeInfos.datas.infos["Commun"][item][item].state = "edit"
                state.codeInfos.datas.infos["Commun"][item][item].text[1] = "-"
            }

            return state
        },

        doEdit : (state, action) => {
            const item = action.payload.item
            state.codeInfos.datas.infos["Commun"][item][item].state = "isEdit"
            state.codeInfos.datas.infos["Commun"][item][item].text[1] = action.payload.value
            return state
        }

    }
})


export const {openScenario, closeScenario, setName, 
    setXML, openRename, setNewName, setScenarioID, 
    setCodeInfos,setNavMenu, setNavTab, onClickCDALine,
    setCodesState, setCodeState, setNavMenusState, setNavMenuState,
    setIDsList, eraseCodeInfos, setSpecimenState,setSpecimenTab,cancelEdit,
    openEditCode, closeEditCode,doEdit, onClickCDALineChange
} = scenarioSlice.actions;