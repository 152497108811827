import React  from 'react'
import { DashBoardTable,   SubMenu, ThreeDotMenu } from 'onaosreact'
import { batch, useDispatch, useSelector } from 'react-redux'
import { deleteScenario } from '../../../redux/UserDatas/UserDatas'
import { getAPI, putAPI } from '../../../api/api'
import { openRename, setXML, setScenarioID, setCodesState, setNavTab, setIDsList, eraseCodeInfos } from '../../../redux/scenario/scenario'
import { useNavigate } from 'react-router-dom'



export const ScenarioTable = (props) => {



    const userScenarios = useSelector( (state) => state.userDatas.scenarios)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const openEvent = (e) => {
        const scenarioid = userScenarios.bodyInfos[e]["id"]
  
        getAPI("/generatedLink/"+scenarioid, tokenRefresh).then(data => {
            if (data.link){
                batch( ()=> {
                    dispatch(setXML(process.env.REACT_APP_URL_FRONT + "/" + data.link))
                    dispatch(setScenarioID(scenarioid))
                    if (data.states) dispatch(setCodesState(data.states))
                    if (data.idList) dispatch(setIDsList(data.idList))
                    dispatch(eraseCodeInfos())
                    dispatch(setNavTab(0))
                    navigate("/scenarios"); 
                })
            } 
        })
    
    }


    const changNameEvent = (e) => {
        dispatch(openRename({
            position :e,
        }))
    }

    
    const deleteEvent = (e) => {

        const confirm = window.confirm("Confirmer la suppression de ce scénario ? (Action irreversible !)")
        if (confirm){
            const scenarioID = userScenarios.bodyInfos[e]["id"]
            putAPI("/deletescenario", tokenRefresh, {scenarioID : scenarioID}).then(data => {
                if (data.ok) dispatch(deleteScenario({position : e}))
                if (!data.uservalid) {
                    window.alert("Action non autorisée, vous n'êtes pas à l'origine de ce scénario")
                }
            })
        }
        
    }

    const showOverView = (e) => {
        // const datas = {
        //     scenarioid : userScenarios.bodyInfos[e]["id"]
        // }
        // putAPI("/scenariocodes", tokenRefresh, datas).then(data => {
        //     if (data.requestcodes === undefined) data.requestcodes = []
        //     batch( ()=> {
        //         dispatch(loadListCodes({codes : data.requestcodes}) )
        //         dispatch(openOverview())
        //     })
        // })
    }

    const subMenuData = [
        {label : "Ouvrir", click :  openEvent},
        {label : "-"},
        {label : "Renommer",  click :  changNameEvent},//for owner only
        {label : "Modifier", click :  openEvent},//todo : convert to "Dupliquer" ? 
        {label : "Supprimer", click :  deleteEvent},//for owner only
        // {label : "-"},
        // {label : "Aperçu", click : showOverView},
    ]


    return(
        <div style = {{height : '90%'}}>
            
            <DashBoardTable 
                head = {userScenarios.head}
                body = {userScenarios.body}
                // ThreeDotMenu ={
                // <ThreeDotMenu 
                //     subMenu = {
                //     <SubMenu 
                //         items = {subMenuData}
                //         autoIcon = {true}
                //     />
                //     }
                // /> 
                // }

                onClick = {(k)=> openEvent(k)}
            />
       
        </div>
        
    )


}