import React, { useEffect } from "react";
import { NeuMorphisme, useWindowSize} from "onaosreact"
import 'onaosreact/dist/index.css';
import {  useDispatch, useSelector } from "react-redux";
import { DashScenario } from "./scenario/DashScenario";
import { DashImport } from "./importfiles/DashImport";
import { Journalisation } from "./journal/Journal";
import { loadListCodes } from "../../redux/requestcode/requestcode";
import { DictionnariesCodes } from "./dictionnariesCodes/DictionnariesCodes";
import { Report } from "./report/Report";
import { DashSGL } from "./sgl/DashSGL";



export const DashBoardDatas = (props) => {

    const DashBoardMenu = useSelector( (state) =>state.DashBoardMenu ) 
    const windowSize = useWindowSize()
    const dispatch = useDispatch()

    const withSize = () => {
      const multi = window.devicePixelRatio


        if  (windowSize.width<=1600) return 1200/multi + "px"

        if  (windowSize.width<=1920) return 1500/multi + "px"
        if  (windowSize.width>1920 ) return 2100/multi+"px"
    }
    
  const divDashStyle = {
  
    width: withSize(),
    //padding : "20px",
    height : "90vh",
    overflow : "hidden",
    position : 'absolute',
    top : '70px',
    right : '40px'
  }

  useEffect( () => {
    dispatch(loadListCodes({codes : []}) )
    // eslint-disable-next-line
  }, [])


    const getDashPage = (shortTitle) => {
        if (shortTitle === "sgl") return (<DashSGL />)
        if (shortTitle === "scenar") return <DashScenario />
        if (shortTitle === "journal") return <Journalisation />
        if (shortTitle === "import") return <DashImport />
        if (shortTitle === "report") return <Report />

        // if (shortTitle === "files") return (<div></div>)
        // if (shortTitle === "comptes") return (<div></div>)
        // if (shortTitle === "codeslink") return (<DictionnariesCodes />)
    }

    return(
        <NeuMorphisme style = {divDashStyle}>
        
            {/*<Title text = {DashBoardMenu.page[0]}/>*/}
            {
                getDashPage(DashBoardMenu.page[1])
                
            }

              
        </NeuMorphisme>
  
    )

}