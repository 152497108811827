import React, {useState, useEffect, useRef} from 'react'

import { ClientCard, NeuMorphisme, TextBoldSearch, LOINCCard, InputSearch, FilterLoincSearch, Navtags, 
    SearchLOINCNav, LOINSearchResult, LoincSearchBtns, IconButton, ModalWindow, ModalWindowSave }
    from 'onaosreact'

import CloseIcon from '@mui/icons-material/Close'
import { batch, useDispatch, useSelector } from 'react-redux'
import { addHistoryCompletion, setAutoCompletionListing, setFilterSearch, setFiltersSearch, setNewFilterName, setRadiosChoice, showFilterName } from '../../redux/search/search'
import { getAPI, putAPI } from '../../api/api'




export const Search = ({onValidate, onClose}) => {

    const [filter, showFilter] = useState(false)
    const [data, setData] = useState({})
    const [dataTag, setDataTag] = useState([])
    const [loincChoose, setLoinc] = useState(-1)
    const [allLOINC, setAllLOINC] = useState([])
    const [loincsFind, setLoincsFind] = useState()
    const [firstLoad, setFirst] = useState(true)
    const [head, setHead] = useState([])
    const [pageNumber, setPageNumer] = useState(1)

    // const [components, setComponent] = useState([])
    // const [filtersList, setFiltersList] = useState([{label : "Filtres enregistrés", value : 0}])
    const filtersList = useSelector(state => state.search.listingFilter)
    const components = useSelector(state => state.search.listingCompletion)
    const showFilterNameWindow = useSelector(state => state.search.showSaveFilter)
    const newFilterName = useSelector(state => state.search.listingFilterNewName)
    const [filterChoice, setFilterChoice] = useState(0)


    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const radioDefault = useSelector(state => state.search.radioDefault)
    const isOpenSearch = useSelector(state => state.search.open)
    const tmpHistory = useSelector(state => state.search.temporaryHistoryCompletion)

    const clientLocalInfos = useSelector(state => state.search.clientLocalInfos)
    const clientLoincInfos = useSelector(state => state.search.clientLoincInfos)



    const dispatch = useDispatch()
    const refInput = useRef()


    const RadioValue = [
      {label : "Automatique", value : "auto"},
      {label : "Début par", value : "start"},
      {label : "Recherche exacte", value : "exact"},
      {label : "Contient tout", value : "all"},
    ]


    const reset = () => {
      const tmp = {...data}
      Object.keys(data).forEach( (v, k )=> {
        Object.entries(data[v]).forEach((vv, kk)=> {
          vv[1].forEach((val, pos)=> {
            tmp[v][vv[0]][pos].check = false
          })
        })
      })
      setData(tmp)
    }
      
    const getInputStyle = () =>  {
        return filter ? {
            width : '1000px',  
            backgroundColor: 'white',
            boxShadow:"",
            borderRadius:"5px 5px 0px 0px",
            borderBottom:"1px solid #f7f7f7",
            position:'relative',
        } : {
            width : '1000px',  
            borderBottom:"1px solid transparent",
        }

    }
    
    const defineTag = (filters) => {
      const tmp = []
      Object.entries(filters).forEach( v => {
        Object.entries(v[1]).forEach(vv => { 
          vv[1].forEach(value => {
           if (value.check) tmp.push({libelle : value.libelle, title : vv[0] === "" ? v[0] : vv[0], trueTitle :  v[0], trueSubtitle :  vv[0], truePosition : value.position})
         })
       })
     })
     return tmp
    }

    const searchValidate = (radio, filters) => {
      showFilter(false)
      batch( () => {
        // dispatch(setRadiosChoice({radio : radio}))//search type for component 
        setData(filters)// filters  
        setDataTag(defineTag(filters)) // filters tag upside search table
      })
    }

    const tagClose = (title, subtitle, valuePosition, tagPosition) => {
        const tmpFilter = [...dataTag]
        tmpFilter.splice(tagPosition,1)
        const tmpData = {...data}
        if (tmpData[title] !== undefined && tmpData[title][subtitle] !== undefined  && tmpData[title][subtitle][valuePosition] !== undefined )  tmpData[title][subtitle][valuePosition].check = false
        batch( () => {
          setData(tmpData)// filters  
          setDataTag(tmpFilter) // filters tag upside search table
        })
    }

    const tagClear = () => {
      setDataTag([])
      reset()
    }

    const NeuStyle = {
      width: '98%',      
      overflow : 'auto'
  }


    const pageStyle = {
        height: '98vh', 
        width : '95%', 
        margin : '10px auto', 
        display:'flex', 
        paddingLeft:'20px', 
        overflow:'auto',
    }



    
    const allPageStyle = {
        zIndex: '1000',
        position: 'absolute',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
    }

    const closeSearch = () => {
      onClose()
    }

    const searchValidateChoice = () => {//validation of new loinc
      onValidate(allLOINC[loincChoose].loinc)
    }


    useEffect( () => {
      if (isOpenSearch){
      if (firstLoad) {
        batch( () => {
          setFirst(false)
          searchText(refInput?.current?.value, 'composant')
        })
        } else {
        searchText()
      }
    }
    },[dataTag, isOpenSearch])



    useEffect( () => {
      if (components.length === 0) {
        getAPI("/findallcomponent", tokenRefresh).then(data => {
          dispatch(setAutoCompletionListing(data))
        })
      }
    },[])


    // useEffect( () => {
    //   getAPI("/findfilter", tokenRefresh).then(data => {
    //     if (data.ok)  dispatch(setFilterSearch(data.data))
    //   })
    // },[])


    const searchText = (text = '', mode = '') => {     
      if (text === '' && (refInput?.current?.value === undefined || refInput?.current?.value === "")) return
      const datas = {
        composant : text === "" ?  refInput?.current?.value : text,
        searchinfos : {
            codeType : clientLocalInfos.typeCode,
            mode : mode === '' ? radioDefault : mode,
            filters : dataTag
        },
      }
      putAPI("/findloinc", tokenRefresh, datas).then(data => {
        showFilter(false)
        if (data.results !== undefined){
          setHead(data.head)
          setAllLOINC(data.results)
          setLoincsFind(data.results.slice(0,100))
          if (data.results.length > 0) dispatch(addHistoryCompletion(text))
        }
        if (data.filters !== undefined) {
          setData(data.filters)
        }
        if (data.results !== undefined && data.filters !== undefined) {
          setPageNumer(1)
          setLoinc(-1)
        }  
      })
    }

    const selectChangeFilter = (value) => {
      getAPI("/getFilter/"+value, tokenRefresh).then(dataAPI => {
        if (dataAPI.ok) {
          const tmp = {...data}
          Object.entries(dataAPI.data).forEach((v, k) => {
            Object.keys(v[1]).forEach((vv, kk)=> {
              Object.values(v[1]).forEach((vvv, kkk)=> {
                vvv.forEach(libelle =>{
                  const block = v[0]
                  const title =vv
                  const id = tmp[block][title].findIndex(line => line.libelle === libelle.libelle)
                  if (id !== -1) {
                    tmp[block][title][id].check = true
                  } 
                })
              })
            })
          })
          setData(tmp)
          searchValidate("auto", tmp)
        }
      })
      setFilterChoice(value)


    }

    const addNewFilterName = () => {
      if (newFilterName === "") {
        dispatch(showFilterName(false))
        return
      }
      const datas = {
        name : newFilterName,
        filters : dataTag
      }
      putAPI("/addFilterName", tokenRefresh, datas).then(date => {
        if (data.ok) {
          dispatch(setFilterSearch(data.data))
        }
      })
      dispatch(showFilterName(false))
    }



    return (
      <React.Fragment>
        {isOpenSearch &&
        <div style = {allPageStyle}>
        {showFilterNameWindow && 
        
        <ModalWindow
        modalObject = {
        <ModalWindowSave
            title = "Nom pour le groupe de filtres"
            placeHolder = ""
            change ={(e)=>dispatch(setNewFilterName(e))}
            cancel = {()=>dispatch(showFilterName(false))}
            save = {addNewFilterName}
        />
        }
    />
        
        }
        <NeuMorphisme style = { pageStyle }>
            <div style={{width : '16%', display:'flex', flexDirection:'column', height : '100%', minWidth : '245px'}}>
              <div style ={{display:'flex', flexDirection:'column'}}>
                  <TextBoldSearch text = "Entrée locale" />     
                  <ClientCard 
                      searchType = {true}
                      infos = {clientLocalInfos}
                      showIcon = {false}
                  />
              </div>
              <div style ={{display:'flex', flexDirection:'column'}}>
                  <TextBoldSearch text = "Choix LOINC historique" />  
                  <LOINCCard    
                    searchType = {true}                   
                    infos = {clientLoincInfos}
                    />
              </div>
              <div style ={{display:'flex', flexDirection:'column'}}>
                  <TextBoldSearch text = "LOINC sélectionné" /> 
                    {loincChoose !== -1 && allLOINC[loincChoose] !== undefined && 
                    <LOINCCard    
                      searchType = {true}                   
                      infos={{
                        code: allLOINC[loincChoose].loinc,
                        libelle: allLOINC[loincChoose].composant,
                        attributs: [
                          {
                            label: "Grandeur",
                            value: allLOINC[loincChoose].grandeur
                          },
                          {
                            label: "Milieu",
                            value: allLOINC[loincChoose].milieu
                          },
                          {
                            label: "Echelle",
                            value: allLOINC[loincChoose].echelle
                          },
                          {
                            label: "Temps",
                            value: allLOINC[loincChoose].temps
                          },
                          {
                            label: "Technique",
                            value: allLOINC[loincChoose].technique
                          }
                        ]
                      }}
                    />
                }
              </div>
            </div>
            <div style = {{width : "84%",display:'flex', flexDirection:'column', height : '100%'}} >
              <div style ={{display:'flex', flexDirection:'column', maxHeight : '12%'}}>
                <div style = {{display : 'flex', justifyContent : 'space-between', width : '100%', alignItems: 'center'}}>
                  <TextBoldSearch text = "Recherche de code LOINC" />   
                  <IconButton> 
                    <CloseIcon 
                      style = {{color : '#5F6368', marginRight:'50px'}}
                      onClick = {closeSearch}
                    />
                  </IconButton>
                </div>
                <div>
                <div style = {{display : "flex", gap : '50px', alignItems:'center', }}>
                <InputSearch 
                    iconFilter = {true}
                    placeHolder="Rechercher des composants"
                    style={getInputStyle()}
                    onChoice={(e) => searchText(e)}
                    auto={true}
                    autoOptions={components}
                    valueChoice={(e) => console.log('choice : ',e)}
                    showFilter = {()=>showFilter(!filter)}
                    autoClean = {false}
                    defaultValue = {clientLoincInfos.libelle}
                    inputRef = {refInput}
              
                />
                <LoincSearchBtns 
                  onClick = {searchValidateChoice} 
                  showValidateBtn = {loincChoose !== -1}
                />
                </div>
                {filter && 
                    <FilterLoincSearch
                      close = {()=>showFilter(false)}
                      data = {data}
                      onSearch = {searchValidate}
                      onReset = {tagClear}
                    />
                }
                </div>
              </div>

              <div style ={{display:'flex', flexDirection:'column', height:'88%'}}>
                <Navtags 
                  dataTag = {dataTag}
                  onClick = {tagClose}
                  reset = {tagClear}
                  style = {{maxHeight : '20%'}}
                  recordFilter = {filtersList}
                  onFilterChoice = {selectChangeFilter}
                  filterSelected = {filterChoice}
                  record = {()=>dispatch(showFilterName(true))}
                />
                {loincsFind !== undefined &&
                <NeuMorphisme style = {{...NeuStyle, minHeight : '500px', borderRadius: "0px", paddingTop : '0px',maxHeight : dataTag.length > 0 ?  '70%' : '85%' }}>
                <LOINSearchResult 
                    headInfos = {head}
                    body = {loincsFind}
                    style = {{ overflow:'auto', minWidth : '100%',}}
                    onClick = {(index)=> index+(100*pageNumber-100) === loincChoose ? setLoinc(-1) : setLoinc(index+(100*pageNumber-100))}
                    isSelected = {100*pageNumber > loincChoose ?  loincChoose-(100*pageNumber-100)   :  -1}
                  />  
                </NeuMorphisme>
                } 

                {loincsFind!== undefined  && allLOINC !== undefined &&  
                  <div style = {{height : '5%' , width : '100%', display : 'flex', justifyContent:'center', alignItems:'center', marginTop:'10px', marginBottom : '10px'}}>
                    <SearchLOINCNav 
                      numberPage = {Math.ceil(allLOINC.length / 100)}
                      onClick = {number => {setPageNumer(number); setLoincsFind(allLOINC.slice(number*100-100,number*100))}}
                      numberResult = {allLOINC.length}
                      dataByPage = {allLOINC.length >= 100 ? 100 : allLOINC.length}

                    />
                  </div>
                }
              </div>
            </div>
        </NeuMorphisme>
        </div>}
        </React.Fragment>
    )

}