import {DashBoardTable}  from 'onaosreact'
import { useSelector } from 'react-redux'
import React from 'react'


export const JournalTable = (props) => {

    const userDatas = useSelector( (state) => state.journalDatas.datas)

    return (

        <div style={{ height: '90%', overflow: 'hidden auto' }}>
            <DashBoardTable
                head={userDatas.head}
                body={userDatas.body}
            />
        </div>

    )
}
