import { ModalEditionCDA, ModalWindow } from "onaosreact"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { putAPI } from "../../../api/api"
import { closeEditCode, doEdit } from "../../../redux/scenario/scenario"






export const CodeEdition = () => {
    const editInfos = useSelector(state => state.scenario.editCodeInfos)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const [editDatas, setEditDatas] = useState({})
    const dispatch = useDispatch()


    useEffect(()=>{
        const datas = {
            item : editInfos.item,
            itemValue : editInfos.itemValue,
            value : editInfos.value,
        }
        setEditDatas(datas)
    },[])

    const onChange = (e) => {
        const datas = {...editDatas}
        datas.value = e
        setEditDatas(datas)
        // setInputVal(e)
    }

    const save = () => {
        const dataEdit = {
            ...editInfos,
            value :editDatas.value,
            action : "edit"
        }
        putAPI("/editCode", tokenRefresh, dataEdit).then(data => {
            if (data.ok) {
              //change local infos (value and state)
              dispatch(doEdit({
                item : editInfos.item,
                value : editDatas.value
              }))
            }
            dispatch(closeEditCode())
        })
    }

    const close = () => {
        dispatch(closeEditCode())
    }


    return(
        <ModalWindow>
            <ModalEditionCDA 
                title = "Édition du dictionnaire"
                datas = {editDatas}
                cancel = {close}
                save = {save}
                onChange = {onChange}

            />
                
{/*             
            <div>
                <h2>Edition du dictionnaire</h2>
                <div>item : {editInfos.item}</div>
                <div>valeur : {editInfos.itemValue}</div>
                <InputOnaosModal change = {onChange} value = {inputValue} placeHolder="Saisir une valeur"/>
                <br/>
                <div style = {{display : "flex", gap : "10px", marginTop : "10px", justifyContent: "center"}}>
                <button onClick={save}> Save </button>
       
                <button onClick={close}> Close </button> 
                </div>
            </div> */}


        </ModalWindow>
    )

}