import { ButtonBluePlus } from 'onaosreact'
import React  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPI } from '../../../api/api'
import { fetchAllSGLDefault, openSGL } from '../../../redux/sgl/sgl'




export const HeadNavSGL = (props) => {

    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const dispatch = useDispatch() 

    const searchBar = {
        display : "flex",
        width : "100%",
        margin : "10px auto 20px 20px",
        alignItems : "center"

      }
    
      const openSGLWindow = () => {
        getAPI("/sglslisting",tokenRefresh).then(data => {
          if (data.sgls){
            dispatch(fetchAllSGLDefault(data.sgls))
            dispatch(openSGL())
          }

        })
      }

     return(
        <div style = {searchBar}>
            <ButtonBluePlus 
                text = "Ajouter un SGL"
                onClick = {openSGLWindow}
                style = {{width : '180px'}}
            />
      </div>
    )



}