import { ModalWindow, ModalWindowSave } from 'onaosreact'
import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { putAPI } from '../../../api/api'
import { closeScenario, setNewName } from '../../../redux/scenario/scenario'
import { renameScenario } from '../../../redux/UserDatas/UserDatas'


export const RenameScenario = (props) => {


    const scenarioState = useSelector((state) => state.scenario)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const scenarioInfos = useSelector(state => state.userDatas.scenarios)
    const scenarioItem = useSelector(state => state.scenario.scenarioSelectPosition)

    const dispatch = useDispatch()

    const cancelScenario = () => {
        dispatch(closeScenario())
    }

    const saveScenar = () => {



        const data = {
            scenarioID : scenarioInfos.bodyInfos[scenarioItem].id,
            newName : scenarioState.newName
        }
        putAPI("/renamescenario", tokenRefresh, data).then(data => {
            cancelScenario()
            if (data.ok) {
                dispatch(renameScenario({
                    newName :  scenarioState.newName,
                    position : [scenarioItem]
                }))
            }
            if (!data.uservalid) window.alert("Action non autorisée, vous n'êtes pas à l'origine de ce scénario")

        })


    }



    return(
        <React.Fragment>
        {scenarioState.openRename &&
        <ModalWindow
            modalObject = {
            <ModalWindowSave
                title = "Nouveau nom du scénario"
                placeHolder = {scenarioInfos.body[scenarioItem][0]}
                change = {(e)=>dispatch(setNewName({name : e}))}
                cancel = {cancelScenario}
                save = {saveScenar}
            />
            }
        />
        }
        </React.Fragment>
    )




}