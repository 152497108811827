import { createSlice } from "@reduxjs/toolkit";



export const overviewScenarioSlice = createSlice({
    name : "overviewScenario",
    initialState:{
        open : false, 
    },

    reducers: {
        openOverview  : (state, action) => { 
            state.open = true
            return state
        },
        closeOverview : (state,action) => { 
            state.open = false
            return state
        },
 

    }
})


export const {openOverview, closeOverview} = overviewScenarioSlice.actions;