import { DashBoardTable, SubMenu, ThreeDotMenu } from 'onaosreact'
import React from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { putAPI } from '../../../api/api'
import { setPage } from '../../../redux/dashboardmenu/DashBoardMenu'
import { openLinkDict } from '../../../redux/dictionnary/dictionnary'
import { setCodeTableDatas, setLinesCheck } from '../../../redux/dictionnarycodes/dictionnaryCodes'


export const DictionnaryTable = (props) => {

    const dispatch = useDispatch()

    const loadDictionaries = useSelector( (state) => state.dictionnary.list)
    const infosDictionaries = loadDictionaries.infos
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const mockAction = (id, params) => {
        if (params === 'link') dispatch(openLinkDict())//TODO : pass info about dictionnary to link

        console.log(id, params)
    }

    const subMenuData = [
        // {label : "Liaisons", click :  mockAction, params : 'link'},
        // {label : "-"},
        {label : "Renommer",  click :  mockAction, params : 'rename'},
        {label : "Supprimer",  click :  mockAction, params : 'del'},
    ]


    const linkClick = (line, params ) => {
        //API request on line and params 
 
        // const datas = {
        //     id  : infosDictionaries[line]["id"],
        //     // type  : infosDictionaries[line]["type"],//probably not necessary now
        //     // dicoidprev  : infosDictionaries[line]["prevCollection"],//probably not necessary now
        //     action : params
        // }

        // putAPI("/dictionnariescodes", tokenRefresh,datas ).then(data => {
        //     batch( ()=> {
        //         dispatch(setLinesCheck({linesCheck:[]}))
        //         dispatch(setCodeTableDatas({
        //             datas : data,
        //             typeTable : params,
        //         }))
        //         dispatch(setPage(["",'codeslink']))
        //     })
        // })


        
    }


    return(

        <div style = {{display : 'flex', flexDirection:'column', justifyContent:'left', margin : '20px',}}>
        <div style = {{height:'70vh'}}>
        <DashBoardTable
            head = {loadDictionaries?.head}
            body = {loadDictionaries?.body}
            // ThreeDotMenu ={
            // <ThreeDotMenu
            //     subMenu = {
            //     <SubMenu
            //         items = {subMenuData}
            //         autoIcon = {true}
            //     />
            //     }
            // /> 
            // }
            cellClick = {
                [
                    false, 
                    false, 
                    false,
                    (line) => linkClick(line, 'all' ),
                    (line) => linkClick(line, 'new'),//nouveau
                    (line) => linkClick(line, 'delete'),
                    (line) => linkClick(line, 'stillvalidate'),
                    (line) => linkClick(line, 'nonconforme'),//
                    (line) => linkClick(line, 'check'),//à vérifier
                ]
            }
            tooltips = {
                [
                    '',
                    '',
                    '',
                    '',
                    "Accéder à vos codes",
                    "Créer un scénario \nà partir de ces codes",
                    "Voir les codes non retrouvés dans \ncette version de votre dictionnaire",
                    "Voir les codes déjà validés",
                    "Voir les codes non conformes",
                    "Créer un scénario \nà partir de ces codes",

                ]
            }

        />
                  </div>
            </div>
            
    )


}
