import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPI } from '../../../api/api'
import { fetchScenarios } from '../../../redux/UserDatas/UserDatas'

import { HeadNav } from './HeadNav'
import { NewScenario } from './NewScenario'
import { RenameScenario } from './RenameScenario'
import { ScenarioTable } from './ScenarioTable'



export const DashScenario = (props) => {

    const scenarioState = useSelector((state) => state.scenario)

    return (
     
        <div style = {{height : '100%', overflow : 'hidden'}}  >

            {scenarioState.open && <NewScenario />}
            <RenameScenario />
            <HeadNav />
            
            <ScenarioTable />
            
            
            
          

     </div>
    )



}