import {Title, MenuItem, NeuMorphisme, Tooltip} from "onaosreact"
import { batch, useDispatch, useSelector } from "react-redux"
import { putAPI } from "../../../api/api"
import { setCodeInfos, setNavMenu, setNavMenusState } from "../../../redux/scenario/scenario"


import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import LocalPoliceRoundedIcon from '@mui/icons-material/LocalPoliceRounded';






export const MenuCheck = () => {

    const dispatch = useDispatch()
    const menu = useSelector(state => state.scenario.nav.menu)
    const menuState = useSelector(state => state.scenario.navIteamState)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const scenarioID = useSelector(state => state.scenario.scenarioID)
    const lineID = useSelector(state => state.scenario.codeInfos.datas.metainfo.id)

    const page = {
        "du Compte Rendu" : "CR",
        "du CDA" : "CDA",
        "du Code Local" : "Local",
        "du Code LOINC" : "LOINC",
    }

    
    const page2 = {
        "Modifier mon dictionnaire" : "editlocal",
    }


    const setPageMenu = (valItem, n) => {
        const clickDatas = {
            scenarioID : scenarioID,
            line : lineID,
            action:valItem,
        }

        putAPI("/idinfos", tokenRefresh, clickDatas).then(data => {
            if (data.datas && data.datas.metainfo ){
                batch(()=> {
                    dispatch(setCodeInfos({datas : data.datas}))
                    dispatch(setNavMenu(n))
                    dispatch(setNavMenusState(data.itemState))
                })
            }

        })
    }

    const styleDiv = {
        width:"250px",
        backgroundColor : "#FFFFFF",
     
    }

    const getIcon = (text) => {
        const style =  {
            height : "16px",
            width : "16px"
        }
        if (text === "à vérifier") return <Tooltip doOver={true} content = "A vérifier" direction = "top" ><VerifiedUserRoundedIcon style = {{...style, color : "#205E7C"}}/></Tooltip>
        if (text === "en cours") return  <Tooltip doOver={true} content = "En cours" direction = "top" ><VerifiedUserRoundedIcon style = {{...style, color : "#D7872F"}}/></Tooltip>
        if (text === "conforme") return  <Tooltip doOver={true} content = "Conforme" direction = "top" ><VerifiedUserRoundedIcon style = {{...style, color : "#54A67B"}}/></Tooltip>
        if (text === "non conforme") return  <Tooltip doOver={true} content = "Non conforme" direction = "top" ><LocalPoliceRoundedIcon style = {{...style, color : "#C43C3C"}}/></Tooltip>

    }

    return(
        <NeuMorphisme  style = {styleDiv}>
   
        <Title text = "Vérification" />
        {Object.entries(page).map( (v, k) => (
            <MenuItem 
                text = {v[0]}
                click = {()=> setPageMenu(v[1], k)}
                active = {k === menu }
                key = {v[1]}
                style = {{fontSize : '14px'}}
                leftIcon = {getIcon(menuState[v[1]])}
            />
        ))}
        <Title text = "Édition" style = {{marginTop : "30px"}} />
        {Object.entries(page2).map( (v, k) => (
            <MenuItem 
            text = {v[0]}
            click = {()=> setPageMenu(v[1], Object.entries(page).length+k)}
            active = {(k+Object.entries(page).length) === menu}
            key = {v[1]}
            style = {{fontSize : '14px'}}
            />
        ))} 
        
        </NeuMorphisme>
    )

}