import { createSlice } from "@reduxjs/toolkit";



export const dictionnarySlice = createSlice({
    name: "dictionnary",
    initialState: {
        list : {
            head :[
                    'Version', 'Type', 'Ajouté par', 'Le', 'Codes totaux','Codes à vérifier',  'Codes nouveaux',
                    'Codes non formes', 'Codes supprimés', ''
                ],
            body : [

                ],
            infos:[],
        },
        others : {
            head : [
                'Version', 'Type', 'Dictionnaire liés', ''
            ],
            body : [

            ],
        },
        // infos : [],
        open: false,
        openLink: false,
        listingForScenario : []
    },


    reducers: {
        fetchDictionaries: (state, action) => {
            if (action.payload.list.body !== undefined) {
                state.list.head = action.payload.list.head
                state.list.body = action.payload.list.body
                state.list.infos = action.payload.list.notshow
                // if (action.payload.list.dictionnarie.infos !== undefined)
                //     state.infos = action.payload.list.dictionnarie.infos
            }
            if (action.payload.list.other !== undefined)
                state.others = action.payload.list.other    
            return state
        },
        openDictionaries: (state, action) => {
            state.open = true
            return state
        },
        closeDictionaries: (state, action) => {
            state.open = false
            return state
        },

        openLinkDict: (state, action) => {
            state.openLink = true
            return state
        },

        closeLinkDict: (state, action) => {
            state.openLink = false
            return state
        },

        linkDict: (state, action) => {

            return state
        },
        fetchForScenario : (state, action)=> {
            state.listingForScenario = action.payload
            return state
        }

    }
})


export const { fetchDictionaries, openDictionaries, 
    closeDictionaries, openLinkDict, closeLinkDict ,
    fetchForScenario
} = dictionnarySlice.actions;