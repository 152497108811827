import { createSlice } from "@reduxjs/toolkit";



export const codesClientInfosSlice = createSlice({
    name : "codesClientInfos",
    initialState:{
        active : "", 
        requestCode: '',
        codeActiveType : '',
        loincInfos : {},
        localInfos : {},
        compilInfos:{},
    },
    reducers: {
        defineActive  : (state, action) => {
            state.active = action.payload.activeCode !== undefined ? action.payload.activeCode : ""
            state.requestCode = action.payload.requestCode !== undefined ? action.payload.requestCode : ""
            state.codeActiveType = action.payload.codeActiveType !== undefined ? action.payload.codeActiveType : ''
            return state
        },

        setLocalInfos : (state, action ) => {
            state.localInfos = action.payload.localInfos
            return state
        },
        setLoincInfos : (state, action ) => {
            state.loincInfos = action.payload.loincInfos
            return state
        },
        setTwoLoincInfos : (state, action) => {
            const old = state.loincInfos
            state.loincInfos = []
            state.loincInfos[0] =  action.payload.loincInfos
            state.loincInfos[1] = Array.isArray(old) ? old[1] : old
            return state
        },
        removeLOINC : (state, action) => {
            if (Array.isArray(state.loincInfos)) state.loincInfos = state.loincInfos[1]
            return state
        },

        setLocalResult : (state, action) => {
            state.localInfos.resultatExample = action.payload.example
            return state
        },
        setLocalReference : (state, action) => {
            state.localInfos.referenceValue = action.payload.reference
            return state
        },
        setLocalCommentaire : (state, action) => {
            state.localInfos.Commentaire = action.payload.commentaire
            return state
        }, 
        replaceLocalInfos : (state, action) => {
            state.localInfos.libelle =  action.payload.infos.libelle !== undefined && action.payload.infos.libelle !== "" ? action.payload.infos.libelle : state.localInfos.libelle
            state.localInfos.code = action.payload.infos.code !== undefined && action.payload.infos.code !== "" ? action.payload.infos.code :state.localInfos.code
            state.localInfos.attributs = state.localInfos.attributs.map((v, k)=> {
                return action.payload.infos[v.label] !== undefined ? {label :v.label, value : action.payload.infos[v.label] } : v
            })
            return state
        }

   
    }
})


export const {defineActive, setLocalInfos, setLoincInfos, setLocalResult, 
    setLocalReference, setLocalCommentaire, setTwoLoincInfos, removeLOINC, replaceLocalInfos } = codesClientInfosSlice.actions;