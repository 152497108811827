import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {setDash} from '../redux/connection/log.js'
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";

import { internalDiv } from '../styles/internalDiv.js';
import 'onaosreact/dist/index.css';



import {Menu} from "./menu/Menu.js"
import {DashBoardDatas} from "./dashboarddatas/DashBoardDatas"
import { useWindowSize } from 'onaosreact';
import { MenuViewer } from '../menu/MenuViewer.js';
import { closeOverview } from '../redux/overview/overviewScenario.js';
import { OverViewScenario } from './dashboarddatas/scenario/OverViewScenario.js';


export const Dashboard = (props ) => {

    const windowSize = useWindowSize()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect( () => {


        if (searchParams.has('t') || searchParams.has('sso')) {
            let newParams = []
            setSearchParams(newParams);
            navigate({
                search: createSearchParams(newParams).toString(),
            }, {replace: true});
        } 
        dispatch(setDash(false)) 
        // eslint-disable-next-line
    }, [])



    return (
        <div onClick={()=> dispatch(closeOverview()) }>
            <MenuViewer />
            <div style={internalDiv.mainContainerStyle}>
                <div style={internalDiv.containerStyle }>
                    {windowSize.width > 500 && <Menu />}
                    <DashBoardDatas />
                    <OverViewScenario />
                </div>
                
            </div>
        </div>
    )



}

