import { ButtonClose,  NeuMorphisme, OverView, Title } from 'onaosreact'
import React  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeOverview } from '../../../redux/overview/overviewScenario'


export const OverViewScenario = (props) => {

    const showOverview = useSelector(state => state.overviewScenario.open)
    const requestCodes = useSelector ( state => state.requestCodes.listCodesInfos)

    const dispatch = useDispatch()

    const style = {
        width : '100%', 
        display : 'flex', 
        flexDirection : 'column',
        height : '100%',
    }

    const styleCollapse = {
        width : '300px',
        display : 'flex', 
        flexDirection : 'column',
        zIndex : '800',
        position : 'absolute',
        top : '70px',
        height : '100vh',
        right : '-300px',
        overflow : 'hidden',
        transition: 'right 0.25s ease-in',
    }

    const modifData = () => {
        return requestCodes.map(element => {
            const filter =  element.resultats.filter(dd => dd.libelle !== "Aucun résultat lié")
            return  {
                ...element,
                resultats : filter.length > 0 ? filter : undefined
            } 
        })
      
    }

    return (
        <div style = {showOverview ? { ...styleCollapse,right  : '0px',overflow : 'auto' } : { ...styleCollapse}}>
            {showOverview && 
    
        <NeuMorphisme style = {style} >
            <Title
                text = "Aperçu"
                style = {{ textAlign : 'center'}}
            />
            <ButtonClose 
                onClick = {()=> dispatch(closeOverview())}
                style = {
                    {
                        position : "relative",
                        left : "0px",
                        top : '-40px',
                        width : '10px',
                        marginBottom: '-25px',
                    }
                }
            />
     
            <OverView
                datas = {modifData()}
            />
            <div style = {{width : '100%', height : "75px" }}></div>
    
        </NeuMorphisme>
        }
        </div>

    )

    



}